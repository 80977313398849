body {
    font-family: "Quicksand", sans-serif !important;
}
h1{
    font-size: 43px;
    font-weight: 500px;
    line-height: 53.75;
}

.crousel-overly-inner {
    position: absolute !important;
    display: none !important;
    margin-top: 0;
    background: #ffcc0d;
    visibility: hidden;
    width: 100%;
    height: 0%;
    z-index: 9999;
    margin-top: -21px;
}

.crousel-wrapper .crousel-card:hover .crousel-overly-inner {
    display: block !important;
    visibility: visible !important;
    height: 100% !important;
    min-height: 350px !important;
    width: 100%;
    animation-name: zoomIn;
    animation-duration: 0.7s;
    box-shadow: 0px 0px 3px 1px #000;
    border-radius: 5px;
}

.slick-list {
    overflow-x: clip !important;
    overflow-y: inherit !important;
}

.crousel-overly {
    margin-top: -21px;
    transition: all 1s ease-in-out !important;
}

/* slider css start */
.main-swiper-slider .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.main_swiper_carousel img {
    width: 100%;
    height: auto;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    color: #2449ad;
    font-weight: 800;
    font-size: 18px !important;
}

.swiper-button-prev,
.swiper-button-next {
    top: 58% !important;
    border-radius: 50%;
    padding: 0px 22px;
    background-color: rgba(255, 255, 255, 0.6);
}

.swiper-pagination-bullet {
    background: #fff !important;
}

/* slider css start */
.main-swiper-slider .slider-banner {
    width: 100%;
    height: 100%;
    position: relative;
}

.main-swiper-slider .slider-banner .swiper-carousel-main-banner {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: 100% 0%;
    padding-top: 0%
}

.slider-content {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0px;
    height: 100%;
    color: #fff;
    padding: 60px 0px 60px 0px;
    background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.9023984593837535) 10%,
            rgba(0, 0, 0, 0.865983893557423) 35%,
            rgba(0, 0, 0, 0.0032387955182072714) 95%
    ) !important;
}


.slider-cards .movie-card {
    height: 55vh;
    width: 100%;
    box-shadow: 0px 0px 8px #000;
    cursor: pointer;
}

.slider-content-outer .slider-cards {
    display: flex;
    align-items: center;
}

.movie-banner .slider-cards {
    display: flex;
    align-items: center;
}

.movie-detail.movie-content-section {
    margin-left: 2rem;
}

.slider-cards .movie-card .video-player {
    height: 100%;
    width: 100%;
}

.movie-card video {
    border: 0;
    height: 100%;
    width: 100%;
}


.movie-icon-button button {
    border-radius: 22px;
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    /*color: #2449ad;*/
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 700;
    color: #032152 !important;
}

.movie-icon-button button:hover {
    /* background: #2449ad !important; */
    color: #ffcc00;
}

.sharebtn svg {
    font-size: 16px;
    margin-right: 6px;
}

.movie-iconbtn {
    display: flex;
    position: relative;
}

.movie-detail {
    text-align: start;
}

.movie-EventAttributes {
    margin-bottom: 5px;
    margin-top: 5px;
}

.movie-detail h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 8px;
    margin-top: 0px;
    text-transform: capitalize;
    color: #fff;
}

.swiper-slide img.swiper-carousel {
    width: 100%;
}

.movie-detail span.movie-time {
    font-size: 17px;
    font-weight: 400;
    line-height: 30px;
    color: #fff;
}

.movie-detail p {
    font-size: 17px;
    font-weight: 400;
    line-height: 25px;
    color: #fff;
    letter-spacing: 1px;
    margin-bottom: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 54%;
}

.movie-information button {
    border-radius: 22px;
    background-color: #fff;
    border: 1px solid #fff;
    color: #323232;
    padding: 8px 25px;
    font-size: 16px;
    font-weight: 600;
}

/* aman */
.movie-information-outer {
    display: flex;
}

.movie-information button:hover {
    color: #5A0223;
    background-color: #ffcc00;
    transform: scale(0.5);
    animation-name: zoomInBtn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.rent-option-button button:hover, .movie-icon-button button:hover, .share-movie-btn button:hover {
    color: #5A0223 !important;
    background-color:white !important;
    border: #ffcc00 !important;
    transform: scale(0.5);
    animation-name: zoomInBtn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.rent-option-button button {
    margin-right: 10px;
    transition: all 0.3s ease; 
}

.share-movie-btn {
    /*position: absolute;*/
    right: 8rem;
    top: 0px;
    margin-left: 3px;
}

.share-movie-btn button {
    font-size: 20px;
    padding: 4px 20px;
    border-radius: 22px;
    background-color: #ffcc00;
    /* border: 1px solid #2449ad; */
}

.share-movie-btn button .MuiSvgIcon-root {
    margin-right: 12px;
    font-size: 26px;
}

/* .share-movie-btn button:hover {
    opacity: 0.8;
    border: 1px solid #2449ad;
    background-color: #2449ad;
} */

/* aman */
.movie-EventAttributes ul {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}

.movie-EventAttributes li {
    padding: 4px 0px;
    font-size: 16px;
    font-weight: 800;
    margin-right: 10px;
}

.movie-EventAttributes h6 {
    color: #fff;
    text-decoration: none;
}

.movie-EventAttributes li svg.MuiSvgIcon-root {
    color: #ffb800;
}

.swiper-pagination-bullet {
    width: 8px;
    height: 8px;
}

/* dhakad rating sec css */
.dhakad-rating-sec {
    position: relative;
}

.dhakad-rating-sec .dhakad-rating-title-sec {
    display: flex;
}
.gif-container {
    background: transparent;
    display: inline-block;
}

.dhakad-rating-sec .dhakad-rating-title-sec h6 {
    font-size: 16px;
    font-weight: 700;
    margin-right: 10px;
    line-height: 26px;
    color: #fff;
}

.dhakad-rating-title-sec img {
    width: 50px;
    height: 50px;
    object-fit: fill;
    margin-bottom: 10px;
    
}

.dhakad-rating-sec span {
    font-size: 16px;
    font-weight: 600;
    background: #ffcc00;
    color: #2449ad;
    width: 20px;
    height: 20px;
    text-align: center;
    margin-left: 10px;
    line-height: 20px;
    border-radius: 18px;
    cursor: pointer;
}

.dhakad-rating-tooltip {
    position: absolute;
    bottom: 40px;
    left: 20%;
    opacity: 0;
    visibility: hidden; /* Hide initially */
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0s linear 0.3s; /* Delay visibility transition */
    z-index: 100; /* Ensure tooltip appears above other content */
}

.dhakad-rating-tooltip-btn:hover + .dhakad-rating-tooltip{
    visibility: visible; /* Show when hovered */
    opacity: 1;
    transform: translateY(-10px);
    transition-delay: 0s; /* Remove delay for visibility transition */
}

.dhakad-rating-tooltip span {
    padding: 5px 5px;
    border-radius: 0px;
}

/* slider css end */
/* carousel css start*/
.section-heading h3 {
    color:#5C0223;
    margin: 0px;
    font-size: 1.75rem;
    font-weight: 600;
    line-height: 5rem;
}

.slick-slider.slick-initialized {
    /*padding: 0px 25px;*/
    padding-left: 30px;
    padding-right: 15px;

}

.crousel-wrapper .crousel-card {
    width: 98%;
    margin: 0 auto !important;
}

.crousel-card .crousel-card-outer-sec {
    width: 100%;
}

.crousel-card-banner {
    position: relative;
    height: 200px;
    border: 1px solid #cccc;
    border-radius: 5px;
}

.crousel-card .crousel-card-outer-sec .crousel-card-banner .crousel-card-image {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    object-position: 10% 25%;
}

.crousel-card-banner .image {
    opacity: 1;
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
}

.crousel-overly {
    opacity: 0;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 99999;
}

.crousel-overly a {
    text-decoration: none;
}

.crousel-card-banner:hover .crousel-overly {
    opacity: 2;
    border-radius: 5px;
    z-index: 9999999999999;
    cursor: pointer;
    background-color: #ffcc00;
    /* padding: 6px; */
    transition: opacity 0.25s ease-in,
    bottom 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
}

.crousel-overly-content-outer {
    padding: 10px 6px;
}

.crousel-card .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 4rem;
    color: #ffcc00;
}

/* slider icon css */
.movie-card-wrapper .movie-card .MuiSvgIcon-root {
    font-size: 5rem;
    color: #ffcc00;
}

.crousel-overly .video-player {
    height: 100%;
    width: 100%;
}

.crousel-card-banner-content {
    padding: 5px 0px;
    text-align: left;
}

.crousel-card-banner-content h5 {
    color: #000;
    font-size: 20px;
    margin: 0px;
    font-weight: 500;
    text-transform: capitalize;
}

.crousel-card-banner-content .card-banner-language {
    color: #000;
    font-size: 16px;
    font-weight: 400;
}

.crousel-overly-movie-title {
    font-size: 20px;
    font-weight: 700;
    color: #3968b6;
    margin: 0px;
    letter-spacing: 2px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
}

.crousel-overly-movie-status {
    color: #3968b6;
    font-size: 14px;
    line-height: 25px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 35px;
}

.play-option {
    color: #ffcc00;
    margin-left: 10px;
    font-weight: 500;
    font-size: 17px;
}

.crousel-overly-movie-description {
    text-align: start;
}

.crousel-overly-movie-short-description {
    font-size: 14px;
    font-weight: 700;
    color: #3968b6;
    letter-spacing: 0.5px;
    margin: 6px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.crousel-overly-movie-long-description {
    font-size: 14px;
    font-weight: 500;
    color: #3968b6;
    margin: 0px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.crousel-overly-play-outer {
    height: 200px;
    border: 1px solid #f9c131;
}

.crousel-overly-play-outer video {
    border: 0;
    height: 100%;
    background: #101215;
}

/* slick navigation css */
.slick-prev:before,
.slick-next:before {
    color: #ffcc00 !important;
    border-radius: 50%;
    padding: 0px;
}

/* carousel css end */
/* model setup css start */
.modal-content .modal-component .modal-header {
    position: relative;
    border-bottom: none;
    background: linear-gradient(90deg, #5C0223 0%, #1E2744 100%);
    color: #ffcc00;
}

.modal-content .modal-component .modal-header .btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    color: #fff !important;
}

.modal-content .modal-component .modal-header .btn-close:focus {
    box-shadow: none;
}

.form-header h2 {
    color: #FFCC00;
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 10px;
}
.form{
    width: 452px;
    height: 620px;
}
.form-select{
    color: #000;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    /* padding: 0.7rem 0.75rem ; */
    padding: 10px 10px !important;
    text-indent: 10px;
    font-weight: 300px !important;
    width: 100%;
    outline: none;
}
.form-text{
    color: white !important;
}
.form-select{
    color: #000;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    /* padding: 0.7rem 0.75rem ; */
    padding: 10px 10px !important;
    text-indent: 10px;
    font-weight: 300px !important;
    width: 100%;
    outline: none;
}
.regiseration-valdated-fields {
    display: flex;
    padding-bottom: 10px;

}

.regiseration-label {
    width: 100%;
    color:#fff;
    font-size: 15px;
    font-weight: 300 !important;
    line-height: 20px;
    /* margin-bottom: 10px; */
}

.regiseration-valdated-fields p {
    font-size: 25px;
    color:#FFCC00;
    margin: -2px 0px 0px 5px;
}

.modal-content .modal-component .modal-body {
    padding: 2rem;
}

.model-submit-btn {
    background: linear-gradient(90deg, #FFCC00 0%, #FFB800 100%);
    border: none !important;
    padding: 0px !important;
    border-radius: 30px !important;
    width: 451px;
    height: 54px;

}

.model-submit-btn:hover {
    background-color: #3968b6 !important;
}

.model-submit-btn span {
    color: #5A0223;
    padding: 10px 0px;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;

}

.model-submit-btn span:hover {
    color: #fff !important;
    border: none !important;
}

.model-submit-btn span:focus {
    color: #ffcc00;
}

.modal-form-sign-in-option {
    color: #FFCC00;
    font-weight: 600;
    cursor: pointer;
    padding: 0px 6px;
    text-decoration: none;
}

.modal-form-sign-in-option:hover {
    color: #3968b6;
}

.modal-content .modal-component .form-control:focus {
    box-shadow: none;
    border-color: #ffcc00;
}

.term-and-condition-profile .form-text {
    color: #FFFFFF;
    font-size: 14px;
    /* float: inline-start; */
    /* padding-bottom: 50px; */
    /* display:inline-flexbox; */
}

.term-and-condition-profile input.input-form-checkbox {
    width: 30px;
    height: 15px;
    cursor: pointer;
    /* float: left; */
    /* text-align: left;
    display: flex; */
}

/*  model setup css end  */
/* sign up modal css start */
.register-user-content-outer a.regiter-btn {
    padding: 0px;
    margin-bottom: 20px;
}

.register-user-content-outer button {
    border: 1px solid #ced4da;
    padding: 10px 26px;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}

.register-user-content-outer button:hover {
    border: 1px solid #ffcc00;
    color: #ffcc00;
}

.register-user-content-outer button:focus {
    box-shadow: none;
}

.register-user-content-outer button a {
    padding: 0px;
}


.register-icon {
    /* float: left; */
    font-size: 30px;

}

.register-icon-facebook {
    color: #0a53be;
}

.register-icon-mail {
    color: #2c2c2c;
}

/*sign up modal css end  */
/* languages start*/
.movie-languages-button .movie-lang-btn button {
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color: #ffff !important;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin-right: 12px;
    margin-bottom: 8px;
    border-radius: 22px;
    padding: 10px 24px;
    text-transform: capitalize;
}

.movie-languages-button .movie-lang-btn button:hover {
    background-color: #ffcc00;
    box-shadow: 0px 2px 6px rgba(44, 43, 43, 0.365) !important;
    transition: 0.5s;
}

/* languages end*/
/* Register  start*/
.registration-banner {
    padding-top: 32px;
    padding-bottom: 40px;
}

.registration-content .join-banner {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
}

.registration-content {
    padding: 10px 0px;
    color: #ffff;
    border-radius: 20px;
    background: linear-gradient(to right, #5A0223, #1E2744);
    margin: 0 auto;
    display: flex;
    justify-content: center !important;
    align-items: center !important;

}

.registration-content .join-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    
}

@keyframes zoomIn {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

@keyframes zoomInBtn {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.1);
    }
}

@keyframes zoomInNav {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.05);
    }
}


.registration-content button {
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color:  #032152 ;
    padding: 13px 33px;
    font-size: 26px;
    font-weight: 500;
    border-radius: 22px;
    transition: all 0.3s ease; 
}

.registration-content button:hover {
    color: #5A0223;
    background: white;
    transform: scale(1.1);
    animation-name: zoomIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.registration-content button:focus {
    color: #5A0223;
}

.registration-content h3 {
   
    font-weight: 400;
}

.reges-banner p {
    margin: 0;
    font-size: 1.3rem;
    margin-top: 5px;
    color: #ffcc00;
    font-weight: 500;
    
}

@media screen and (min-device-width: 1012px) and (max-device-width: 1600px) {
.reges-banner p {
    margin: 0;
    font-size: 1.3rem;
    margin-top: 5px;
    color: #ffcc00;
    font-weight: 500;
    /* padding-left: 30px; */
    
}
}



@media screen and (min-device-width: 587px) and (max-device-width: 860px) {
.registration-banner {
    padding-top: 32px;
    padding-bottom: 40px;
}

.registration-content .join-banner {
    width: 100%;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
}

.registration-content {
    color: #ffff;
    border-radius: 20px;
    background: linear-gradient(to right, #5A0223, #1E2744);
    margin: 0 auto;
    padding-left: 40px !important;
   
}

.registration-content .join-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.registration-content button {
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color: #2449ad;
    padding: 11px 33px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 22px;
}

.registration-content button:hover {
    color: #ffcc00;
}

.registration-content button:focus {
    color: #ffcc00;
}

.registration-content h3 {
    font-size: 2.3rem;
    font-weight: 400;
}

.reges-banner p {
    margin: 0;
    font-size: 1.3rem;
    margin-top: 5px;
    color: #ffcc00;
    font-weight: 500;
    /* padding-left: 10px; */
}
}

/* Register  end*/
/* movie-detail css start */




@media screen and (min-device-width: 221px) and (max-device-width: 338px) {

    .registration-content button {
        padding: 1px 2px !important;
    }

    .main-movie-languages {
        padding-top: 20px;
    }

    .movie-languages-button h3 {
        font-size: 1.125rem;
    }

    .registration-content h1 {
        font-size: 20px;
        text-align: center;
        padding: 12px 0px;
    }

    .registration-content h3 {
        font-size: 14px !important;
        /* margin-bottom: 18px !important; */
    }

    .reges-banner p {
        font-size: 13px !important;
        /* padding-left: 16px; */
       
    }

    .registration-banner .col-md-3 {
        text-align: center;
    }

    .footer-text {
        max-width: 100%;
        padding: 10px 10px;
    }



    .registration-banner {
        padding-top: 32px;
        padding-bottom: 40px;
    }
    
    .registration-content .join-banner {
        width: 100%;
        margin: 0;
        display: flex;
        justify-content: space-evenly;
    }
    
    .registration-content {
        padding: 30px 0px;
        color: #ffff;
        border-radius: 20px;
        background: linear-gradient(to right, #5A0223, #1E2744);
        margin: 0 auto;
       
    }

    .reges-banner p {
        font-size: 10px !important;
    }
    
    .registration-content .join-btn {
        display: flex;
       width: fit-content;
       
    }
    
    .registration-content button {
        background-color: #ffcc00;
        border: 1px solid #ffcc00;
        color: #2449ad;
        padding: 11px 33px;
        font-size: 16px;
        font-weight: 500;}}

.main-movie-detail .slider-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 26%;
}

.movie-about p {
    color: #000;
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
}

.movie-cast-detail h3 {
    font-size: 28px;
    font-weight: 500;
}

/* .movie-subtitle {
  display: unset !important;
} */
.movie-subcast {
    /* padding-right: 16px; */
    cursor: pointer;
    text-align: center;
    width: unset !important;
    /* margin: 30px 0px; */
    padding: 5px;
}

.movie-subcast img {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border: 1px solid #dddd;
    border-radius: 50%;
    display: block;
}

/* .movie-cast-detail {
  padding-bottom: 32px;
} */
.movie-subcast h5.card-title {
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    padding-top: 5px;
    line-height: 20px;
}

.movie-subcast h5.card-text {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
}

.movies-slide h3 {
    font-size: 28px;
    font-weight: 500;
    padding-bottom: 10px;
}

.movie-reviews-detail {
    padding: 32px 0px;
}

.reviews-banner {
    display: flex;
    background: #3968b6;
    height: 153px;
}

.reviews-ratenow button:hover {
    background: #3968b6;
    border-color: #ffcc00;
}

.reviews-content {
    text-align: center;
    margin-top: 38px;
}

.reviews-content h5 {
    font-size: 18px;
    font-weight: bold;
    color: #fff;
}

.reviews-content h6 {
    font-size: 16px;
    font-weight: 500;
    color: #fff;
}

.reviews-ratenow {
    display: flex;
    align-items: center;
}

.reviews-content {
    padding: 15px 15px;
}

.reviews-ratenow button {
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color: #0a53be;
    padding: 8px 30px;
    font-size: 16px;
    font-weight: 500;
}

.reviews-ratenow span {
    padding-left: 8px;
    font-size: 16px;
    font-weight: 500;
}

.reviews-ratenow button:hover {
    color: #ffcc00;
}

.reviews-ratenow button:focus {
    color: #ffcc00;
}

/* review css */
.movie-review-content {
    padding: 20px;
}

.review-form-header {
    display: flex;
    background-color: #ffcc00;
}

.review-form-header svg {
    font-size: 24px;
    margin: 12px 15px;
    fill: #fff;
}

.review-form-header h4 {
    margin: 0px;
    color: #ffff;
    padding: 10px 0px;
}

.review-user-detail {
    display: flex;
}

.review-user-profile {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.review-user-profile img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.review-user-title h5 {
    color: #000;
    margin: 0px 0px 0px 20px;
    line-height: 45px;
    font-size: 20px;
    font-weight: 600;
    text-transform: capitalize;
}

/* .rating-icons {
  display: flex;
} */
.start-rating-lable-text {
    color: #000;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 600;
}

.rating-title-area {
    width: 100%;
    border: 1px solid #000;
    padding: 5px 10px;
}

.rating-title-area:focus,
.rating-comment-area:focus {
    border-color: #ffcc00;
}

.rating-title-area:focus-visible,
.rating-comment-area:focus-visible {
    outline: none;
}

.rating-lable-text {
    color: #000;
    font-size: 16px;
    padding: 10px 0px;
}

.rating-comment-area {
    width: 100%;
    border: 1px solid #000;
    padding: 5px 10px;
}

.review-submit-sec {
    margin-top: 15px;
    height: 45px;
}

.review-submit-sec button {
    color: #5A0223;
    font-size: 16px;
    font-weight: 500;
    padding: 5px 30px;
    border-radius: 22px;
    border: none;
    background: linear-gradient(90deg, #FFB800 0%, #FFCB05 100%);
    float: right;
}

.review-submit-sec button:hover {
    opacity: 0.5;
}

.user-review-profile {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.user-review-profile-title {
    padding: 8px 10px;
    margin: 0px;
    text-transform: capitalize;
}

.user-review-profile span {
    padding: 8px 10px;
    font-size: 16px;
}

.user-review-profile-img {
    display: flex;
}

.user-review-card {
    margin-bottom: 25px;
}

.user-review-profile-img img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.user-review-card-header {
    background: #dddd;
}

.user-review-description {
    padding: 0px 10px;
}

.user-review-rating-icons {
    display: flex;
}

.user-review-start-rating-lable-text {
    color: #000;
    padding: 8px 15px;
    font-size: 16px;
    font-weight: 600;
}

.user-review-description-text p {
    color: #000;
    font-size: 16px;
}

.user-reviews-sec {
    max-height: 350px;
    overflow: auto;
}

.user-reviews-sec::-webkit-scrollbar {
    width: 2px;
    display: none;
}

/* movie-detail css end */
/* user detail css start */
.user-detail-sec {
    background: linear-gradient(111.21deg, #5C0223 -0.12%, #1E2744 109.78%);
    border-radius: 80px;
}

.user-detail-sec-inner {
    padding: 2rem 0px;
}

.submit-form-content .form-control {
    border: none;
    border-bottom: 1px solid #787878;
    border-radius: 0px;
}

.user-detail-sec-inner input {
    color: #000;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    border-radius: 100px;
    padding: 0.7rem 0.75rem;
    text-indent: 10px;
    font-weight: 300;
    width: 100%;
    outline: none;
}

.input-label {
    font-weight: 300;
    width: 100%;
    
}

.password-strong {
    top: 56px;
}

.password-strong p {
    position: absolute;
    right: 4%;
    top: 0px;
    color: #000000 !important;
    font-weight: 600;
    display: none;
}

.password-strong div {
    margin: 0px 0px 0px !important;
}

.user-detail-sec-inner input:focus {
    box-shadow: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
}

.input-label input#formBasicName {
    margin-top: 16px;
}

.check-box-class input.input-form-checkbox {
    display: none;
}

.user-detail-submit-btn {
    color: #ffff !important;
    margin-top: 15px;
}

.CheckBoxOuter {
    display: inline-flex;
}

.CheckBox {
    padding: 0px 10px;
}

.CheckBox input {
    border: 1px solid #000;
}

.user-detail-sec-inner .model-submit-btn {
    width: 40%;
}

/* user detail end */
/* bottom navbar css start */
.bottom-navbar {
    background-color: #ffc131;
    margin-bottom: 4rem;
}

.bottom-navbar-inner {
    padding-top: 20px;
    padding-bottom: 20px;
}

.bottom-navbar-profile-sec {
    display: flex;
}

.bottom-navbar-menu {
    background: linear-gradient(111.21deg, #5C0223 -0.12%, #1E2744 109.78%);
    border: 1px solid #5C0223;
}

.profile-img-outer {
    height: 100%;
    border-radius: 50%;
    background-image: url("../../Assets/Images/10.svg.png");
    background-size: 100%;
}

.profile-img-layer {
    height: 100%;
    background-color: #0000008e;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.profile-img-sec {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: relative;
}

.profile-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.profile-snap-icon {
    height: 10px;
}

.profile-details {
    display: flex;
    align-items: center;
}

.profile_title h2 {
    color: #5C0223;
    /* line-height: 6rem; */
    margin: 0px;
    font-size: 25px;
    font-weight: 500;
    text-transform: capitalize;
}

.profile_title p {
    color: #5C0223;
    padding-top: 10px;
    margin: 0px;
    font-style: italic;
}

.change-profile-text {
    padding-top: 18px;
    padding-bottom: 2px;
    color: #5C0223;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

.bottom-navbar-menu-outer .navbar .navbar-nav .nav-item {
    padding-right: 20px;
}

.bottom-navbar-menu-outer .navbar .navbar-nav .nav-link {
    color: #ffff;
    font-size: 17px;
    font-weight: 400;
    padding: 0px;
    border-bottom: 2px solid transparent;
}

.bottom-navbar-menu-outer .navbar .navbar-nav .nav-link:hover {
    border-bottom: 2px solid #ffcc00;
    transform: scale(1.1);
    animation-name: zoomInBtn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.bottom-navbar-menu-outer .active_class {
    color: #ffcc00 !important;
    border-bottom: 2px solid #ffcc00 !important;
    display: inline !important;
}

/* bottom navbar css end */
/* reward card */
.reward-cards-wrapper {
    margin-bottom: 26px;
}

.reward-card-description {
    margin: 0px;
    font-size: 15px;
    font-weight: 300;
    padding: 8px 0px 8px;
    text-transform: lowercase;
}

/*  */
.reward-coupon-card {
    border: 3px solid #ffcc00 !important;
    border-radius: 0px !important;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 68%) !important;
    height: calc(100% - 0px);
}

.reward-coupon-card:hover {
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 68%);
}

.card-header {
    height: auto;
    background-color: #2449ad !important;
    width: 100%;
    padding: 10px !important;
    display: flex;
    border-radius: 0px !important;
    height: calc(100% - 0px);
}

.coupon-card-heading {
    width: 80%;
}

.coupon-card-heading h4 {
    padding: 5px 0px;
    color: #ffcc00;
    margin: 0px;
    height: calc(76% - 0px);
}

.coupon-card-heading span {
    padding: 5px 0px;
    color: #ffff;
    font-size: 14px;
    font-weight: 300;
}

.coupon-card-logo {
    width: 20%;
}

.coupon-card-logo img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.card-body {
    padding: 5px 10px !important;
}

.reward-amount {
    background-color: #ffcc00;
    padding: 5px 10px;
    width: fit-content;
    margin: 0 auto;
}

.term-and-conditon {
    padding: 5px 0px;
}

.term-and-conditon a {
    /* color: #ffcc00; */
    color: #FFCC00;
    font-weight: 400;
    font-size: 14px;
}

.term-and-conditon .modal-form-sign-in-option {
    font-weight: 600 !important;
}

.term-and-conditon a:hover {
    color: #2449ad;
}

/*  about us page css start*/
.about-content {
    margin-bottom: 3rem;
}

.about-content-title .about-main-heading {
    color: #3968b6;
    padding-bottom: 25px;
    font-weight: 600;
    margin-top: 30px;
}

.about-description {
    font-size: 16px;
    color: #000;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 500;
}

/*  about us page css end*/
/* rent css start  */
.rent-content-price {
    justify-content: space-between;
    display: flex;
}

/* rent css end */
/* share css start */
.share-culumn a {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    text-decoration: none;
    color: #000;
}

.share-culumn button:hover,
.share-culumn a:hover {
    opacity: 0.6;
}

.share-culumn h5 {
    font-size: 18px;
    font-weight: 500;
    color: #000;
    line-height: 30px;
}

.share-culumn svg {
    font-size: 25px;
}

.share-culumn svg .share-culumn-icon-link {
    color: #323232;
}

/* share css end */ /* booking-detail-card css start */
.amount-content,
.tax-content,
.total-amount-content {
    display: flex;
    justify-content: space-between;
}

.movie-language-content,
.movie-quality-content {
    color: #000;
    justify-content: space-between;
    display: flex;
}

.booking-card-img img {
    height: 200px;
    width: 100%;
    object-fit: cover;
    object-position: 100% 0%;
    border-radius: 8px;
    display: none !important;
}

.booking-detail-card p {
    font-size: 15px;
    color: #000;
}

.amount-content h4,
.tax-content h4,
.total-amount-content h4 {
    font-size: 20px;
    margin: 0px !important;
}

.booking-id-outer {
    padding-top: 20px;
}

.booking-id-outer h4 {
    margin: 0px;
}

.amount-content p,
.tax-content p {
    margin-bottom: 0px !important;
    font-size: 20px;
    font-weight: 500;
}

.total-amount-content {
    margin-top: 15px;
}

.total-amount-content h4,
.total-amount-content p {
    margin: 0px;
    font-size: 24px;
}

/* booking-detail css end */
/* Payment-option css start */

.payment-option .payment-option-card {
    margin-bottom: 3rem;
}

.payment-option-card-inner {
    padding: 12px 50px !important;
}

.payment-card-content-outer {
    padding: 20px 0px;
}

.payment-card-outer img {
    height: 206px;
    width: 100%;
    border-radius: 8px;
    /* object-fit: cover; */
    object-position: center center;
}

.payment-option-title h2 {
    margin: 0px;
    color: #000;
    padding: 0px 10px;
    text-transform: capitalize;
}

.payment-movie-details .navbar-nav {
    flex-direction: unset;
}

.payment-movie-details .navbar-nav li {
    padding: 5px 10px;
    color: #000;
    font-size: 17px;
}

.payment-option-description {
    padding: 0px 10px;
    font-size: 17px;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.payment-option-button button {
    padding: 10px 30px;
    height: max-content;
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color: #5C0223;
    font-weight: 600;
    border-radius: 30px;
}

.payment-remove-option {
    padding: 8px 12px !important;
    margin-left: 12px;
}

.payment-remove-option svg {
    font-size: 26px;
}

.payment-option-button button:hover {
    color: #ffcc00;
    background-color: #0d6efd;
    border: 1px solid #0d6efd;
}

.payment-option-button button:focus {
    box-shadow: none;
}

.payment-option {
    margin-top: 6rem;
}

.amout-payable-option {
    display: flex;
    justify-content: space-between;
}

.amout-payable {
    font-size: 2rem;
    font-weight: 400;
}

.payment-detail-card {
    padding: 20px 0px;
}

.payment-detail-title h3 {
    color: #000;
    font-size: 2rem;
}

.payment-contect-content {
    display: flex;
    padding-top: 12px;
    justify-content: space-between;
}

.payment-contect-content-redio input {
    margin-top: 29px;
}

.paymeny-contect-content img {
    margin-top: 5px;
}

.payment-tip-prices {
    display: flex;
    justify-content: space-between;
}

.coupon-code-input input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.coupon-code-input button {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    background-color: #5C0223;
    color: #ffcc00;
}

.coupon-code-input button:hover {
    color: #ffcc00;
    opacity: 0.9;
}

.payment-tip-prices .btn-check {
    background-color: #ffcc00;
    border-color: #ffcc00;
    margin-right: 12px;
}

.payment-tip-prices .btn-check:checked + .btn-outline-warning {
    color: #ffcc00;
    background-color: #5C0223;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

.payment-tip-prices button svg {
    font-size: 24px;
    margin-right: 6px;
}

.payment-tip-prices .btn-outline-warning:hover,
.payment-tip-prices .btn-outline-warning:active {
    color: #0d6efd !important;
    background-color: #ffcc00 !important;
    border-color: #ffcc00;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2) !important;
}

/* movie alert start css */
.movie-alert-box {
    justify-content: space-between;
    display: flex;
}

.movie-alert-box button {
    border: 1px solid #ffcc00;
    background: #ffff;
    color: #ffcc00;
    padding: 8px 25px;
}

.movie-alert-box .play-now-btn {
    background: #ffcc00;
    color: #fff;
}

/* movie alert end css */
.swiper-slide-active .crousel-overly-inner {
    left: 30px;
}

/* movie alert end css */

/* chat box css */
.chat-box {
    position: fixed;
    /* z-index: 1000; */
    bottom: 2rem;
    right: 2rem;
}

.sc-launcher {
    cursor: pointer;
}

.sc-chat-window {
    z-index: 10000;
}

.sc-header,
.sc-launcher {
    background-color: #ffcc00 !important;
    z-index: 10000;
    box-shadow: 0px 1px 10px -4px #000 !important;
}

.sc-header--team-name {
    font-size: 16px;
    font-weight: 600;
}

.sc-user-input {
    background-color: #ffcc00 !important;
    text-align: left;
}

.sc-message--text {
    background-color: #ffcc00 !important;
    font-weight: 600 !important;
    padding: 10px 15px !important;
    width: unset !important;
}

.sc-user-input--text:empty:before {
    color: #ffff !important;
    font-weight: 600 !important;
}

/* chat box css end*/

/* email register css */
.email-register-form .movie-checkbox {
    padding: 0px;
}

.email-register-form .movie-checkbox-text {
    color: #fff;
}

/* email register end css */

/* rewards-title start */
.rewards-title h2 {
    line-height: 3rem;
    margin: 0px;
    font-size: 25px;
    font-weight: 500;
}

.rewards-left-table {
    width: 20%;
}

.rewards-right-table {
    width: 80%;
}

table.rewards-table {
    width: 100%;
    display: flex;
}

.rewards-table {
    display: flex;
}

.reward-content table {
    width: 60%;
    margin: 0 auto;
    border-color: #b5b4b4cc;
}

.rewards-title thead {
    background: #3968b6;
    color: #fff;
}

.rewards-table .rewards-rk {
    background: #3968b6;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffff;
    padding-top: 5px;
    margin-right: 18px;
}

.rewards-table .rewards-as {
    background: #f9c131;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffff;
    padding-top: 5px;
    margin-right: 18px;
}

.rewards-table .rewards-pk {
    background: #3968b6;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #ffff;
    padding-top: 5px;
    margin-right: 18px;
}

.rewards-table h4 {
    line-height: 2rem;
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
}

.rewards-rupee {
    color: #3968b6;
    font-weight: 500;
}

/* support page start */
.support-from {
    margin-top: 4rem;
}

.support-header {
    background: linear-gradient(111.21deg, #5C0223 -0.12%, #1E2744 109.78%);
    border-radius: 33px;
    color:#FFCC00;
    margin: 5%;
}

.support-container {
    padding: 2rem 0rem;
    padding: 2% 5%;
}

.support-form-section input.form-control {
    color: #000;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    border-radius: 20px;
    padding: 0.7rem 0.75rem;
    text-indent: 10px;
    font-weight: 500;
    width: 100%;
    outline: none;
}

.support-form-section textarea.form-control {
    color: #000;
    border: none;
    border-bottom: 1px solid #fff;
    background-color: #fff;
    border-radius: 20px;
    padding: 0.7rem 0.75rem;
    text-indent: 10px;
    font-weight: 500;
    width: 100%;
    outline: none;
}

.support-form-section label {
    display: flex;
    color: #fff;
    font-weight: 500;
}

.custom-support-btn {
    text-align: center;
}

.support-container h1 {
    font-size: 2rem;
    color:linear-gradient(95.71deg, #FFCC00 -30.62%, #FFB800 103.5%);
    font-weight: 500;
}

.custom-support-btn .support-btn {
    color: #5C0223 !important;
    padding: 10px 0px;
    font-weight: 600;
    width: 50%;
    background: linear-gradient(95.71deg, #FFCC00 -30.62%, #FFB800 103.5%) !important;
    margin-top: 1.5rem;
    border-radius: 22px !important;
    border: none !important;
}

/* support page end */

/* Purchase history start */
.history-header {
    padding: 10px;
}

.history-header a {
    color: #f9c131 !important;
    text-decoration: none !important;
}

/* Purchase history end */

/* rewards-title end */
@media screen and (min-device-width: 339px) and (max-device-width: 430px) {
   

    .registration-content button {
        padding: 7px 9px !important;
        font-size: 15px !important;
    }}

@media screen and (min-device-width: 221px) and (max-device-width: 480px) {
    .registration-content .join-btn {
        justify-content: center;
    }
    .model-submit-btn {
        width: 100%;
    }
    .form{
        width: 90%;
        justify-content: center;
    }
    .form-select{
        padding: 8px 10px !important;
        width: 100%;
        outline: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        border-radius: 30px;
    }

    .main-movie-languages {
        padding-top: 20px;
    }

    .movie-languages-button h3 {
        font-size: 1.125rem;
    }

    .registration-content h1 {
        font-size: 20px;
        text-align: center;
        padding: 12px 0px;
    }

    .registration-content h3 {
        font-size: 16px !important;
        /* margin-bottom: 18px !important; */
    }

    .reges-banner p {
        font-size: 12px !important;     
    }

    .registration-banner .col-md-3 {
        text-align: center;
    }
    .form{
        width: 270px;
        margin-bottom: 10%;
        padding-bottom: 20%;
    }
    .footer-text {
        max-width: 100%;
        padding: 10px 10px;
    }

    /*  movie-detail  */
    .movie-icon-button .MuiSvgIcon-root {
        font-size: 20px;
        margin-bottom: 0px;
    }

 

    .movie-detail span.movie-time {
        font-size: 18px;
        font-weight: 300;
        line-height: 34px;
        color: #fff;
    }

    .movie-EventAttributes a.active {
        font-size: 15px;
        font-weight: 300;
    }

    .movie-subcast h5.card-title {
        text-align: center;
        font-size: 13px;
        padding-top: 6px;
        line-height: 18px;
        letter-spacing: 0.5px !important;
    }

    .movie-subcast h5.card-text {
        font-size: 12px;
        margin-left: 0px;
        line-height: 15px;
        margin-top: 2px;
        letter-spacing: 0.2px !important;
    }

    .movie-detail p {
        font-size: 15px;
        line-height: 20px;
        padding: 5px 0px;
    }

    .movie-iconbtn p {
        color: #ffcb05;
        margin: 0px !important;
    }

    .movie-lang-btn {
        display: flex !important;
        flex-wrap: nowrap;
        overflow: scroll !important;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
    }

    .main-movie-detail .slider-image img {
        width: 100%;
        object-fit: cover;
    }

    .movie-subcast img {
        width: 96px;
        height: 96px;
        object-fit: cover;
    }

    .movie-detail h1 {
        font-size: 2rem;
        padding: 0px 0px;
        margin: 0;
        line-height: 30px;
    }

    .movie-EventAttributes {
        padding: 0px 0px;
    }

    .slider-image {
        height: 100%;
    }

    .movie-about h3 {
        font-size: 18px;
        padding-bottom: 0px;
    }

    .movie-about p {
        font-size: 14px;
        line-height: 25px;
        font-weight: 300;
    }

    .movie-cast-detail h3 {
        font-size: 18px;
        padding-bottom: 5px;
    }

    .movies-slide h3 {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0px;
    }

    .movie-reviews-detail h1 {
        font-size: 18px;
        font-weight: 500;
        padding-bottom: 0px;
    }

    .reviews-banner {
        display: grid !important;
    }

    .reviews-ratenow {
        justify-content: center;
        padding-bottom: 10px;
    }
}

@media screen and (min-device-width: 481px) and (max-device-width: 575px) {
    .registration-content .join-btn {
        padding: 0;
        justify-content: center !important;
    }
    .model-submit-btn {
        width: 100%;
    }
    .form-select{
        padding: 8px 10px !important;
        width: 100%;
        outline: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        border-radius: 30px;
    }

}

@media screen and (min-device-width: 500px) and (max-device-width: 642px) {
    .registration-content button {
        padding: 11px 10px !important;
        font-size: 22px !important;
    }
    .form-select{
        padding: 7px 10px !important;
        width: 100%;
        outline: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        border-radius: 30px;
    }

}

@media screen and (min-device-width: 642px) and (max-device-width: 767px) {
    .registration-content .join-btn {
        padding: 0;
        justify-content: flex-start;
    }
    .form-select{
        padding: 7px 10px !important;
        width: 100%;
        outline: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        border-radius: 30px;
    }


    .main-movie-languages {
        padding-top: 20px;
    }

    .registration-banner {
        padding: 32px 0px;
    }

    .registration-content {
        display: block !important;
        margin: 0px;
        height: 100%;
        width: 100% !important;
    }

    .registration-content h1 {
        font-size: 24px;
        text-align: center;
        padding: 15px 0px;
    }

    .registration-content .col-md-3 {
        text-align: center;
        width: 100%;
    }

    .footer-text {
        max-width: 100%;
        padding: 10px 10px;
    }

    .reviews-banner {
        display: flex;
        background: rgba(255, 255, 255, 0.5);
        height: 140px;
        width: 100% !important;
        justify-content: space-between;
        padding: 10px 10px;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .reviews-banner {
        height: 140px;
        width: 100% !important;
        justify-content: space-between;
        padding: 10px 10px;
    }
    .form-select{
        padding: 10px 10px !important;
        width: 100%;
        outline: none;
        font-size: 14px !important;
        font-weight: 300 !important;
        border-radius: 30px;
    }


    .movies-sidebar-wrapper .profile-list a.nav-link {
        padding: 4px 15px;
    }

    .main-movie-languages {
        padding-top: 20px;
    }

    /*  .home-slider .movie-card-wrapper .movie-card {
        height: 250px;
        margin-left: 15px;
      }*/


    .movie-information button {
        padding: 10px 12px !important;
        font-size: 12px !important;
        width: 105px !important;
    }

    .responsive-btn button {
        padding: 8px 30px !important;
        font-size: 10px !important;
    }

    .movie-icon-button button {
        padding: 10px 18px !important;
        font-size: 12px !important;
    }



    .rent-option-button button {
        /* margin-left: 10px; */
        width: 105px !important;
    }

    .movie-languages-button .movie-lang-btn button {
        padding: 8px 30px !important;
    }

    .footer-text {
        max-width: 100%;
        padding: 10px 10px;
    }

    .footer-text p {
        font-size: 14px !important;
    }

    /*  movie-detail  */
    .navbar-expand-sm {
        justify-content: center !important;
    }

    .crousel-card-banner {
        height: 150px !important;
    }

    .share-movie-btn button {
        font-size: 12px !important;
        padding: 9px 18px;
        color: #032152 !important;
        font-weight: 700;
        margin-left: 2px;
    }

    .share-movie-btn button .MuiSvgIcon-root {
        font-size: 20px !important;
    }

    .form-header h2 {
        font-size: 28px !important;
    }

    .model-submit-btn span {
        font-size: 16px !important;
        padding: 8px 0px !important;
    }

    .user-detail-sec-inner input {
        padding: 8px 10px;
    }

    .movie-card-wrapper .movie-card .MuiSvgIcon-root {
        font-size: 3.5rem;
    }

    .password-strong {
        top: 48px;
    }

    .icon-btn-40 {
        height: 34px !important;
        width: 34px !important;
        /* margin-left: 4px; */
        /* margin-top: 4px; */
    }

    .ml-10px {
        margin-left: 12px;
         margin-top: 0px; 
    }

    .margleft{
        margin-left: 4px;
        margin-top: 3px;
        height: 34px !important;
       width: 34px !important;
      }
      
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1480px) {

    .icon-btn-40 {
        height: 36px !important;
        width: 36px !important;
    }


    .movie-icon-button button {
        color: #032152 !important;
     }

     .share-movie-btn button {
        font-size: 14px !important;
        padding: 8px 16px;
        margin-left: 8px;
    }


    .responsive-btn button {
        padding: 10px 30px !important;
        font-size: 14px !important;
    }


    .movie-detail h1 {
        font-size: 35px;
        font-weight: 500;
        line-height: 1.2;
        padding-top: 8px;
    }

    .movie-detail span.movie-time {
        font-weight: 400;
        line-height: 25px;
        font-size: 16px;
        color: #fff;
    }

    .movie-detail p {
        font-size: 17px;
        font-weight: 400;
        line-height: 25px;
    }

    .share-movie-btn button .MuiSvgIcon-root {
        margin-right: 12px;
        font-size: 20px;
    }

   

         .margleft{
            margin-left: 8px;
            margin-top: 2px;
            height: 40px !important;
           width: 40px !important;
          }
    

    .movie-information-outer .movie-information button {
        /* padding: 8px 24px; */
        font-size: 16px;
        width: 126px;
    }

    .rent-option-button button {
        margin-right: 10px;
        width: 126px;
    }

    .movie-EventAttributes li.border-rounded {
        width: 50px;
        height: 28px;
        padding: 0px;
    }

    .movie-lang-btn {
        padding-top: 16px;
    }

    .registration-content button {
        padding: 10px 20px;
        font-size: 26px !important;
    }

    .about-content .section-heading h3 {
        padding-top: 0px;
    }

    .about-content {
        margin-bottom: 1rem;
    }

    .reward-cards-wrapper .card-body {
        padding: 9px;
    }

    .reward-cards-wrapper .card-title {
        font-size: 18px;
    }

    .reward-cards-wrapper .reward-card-description {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 5px;
    }

    /* .reward-cards-wrapper .text-center.card-text {
      font-size: 15px;
    } */
    .movie-result {
        height: 190px;
    }

    .movie-search-title h3 {
        font-size: 24px;
    }

    .movie-search-title li.list-item {
        font-size: 14px;
    }

    .movie-search-title p {
        font-size: 14px;
    }

    .form-header h2 {
        margin: 0px;
    }

    .submit-movie-wrapper .mb-5 {
        margin-bottom: 2rem !important;
    }
}

button.sharebtn {
    border-radius: 22px;
    background-color: #fff;
    border: 1px solid #fff;
    color: #323232;
    padding: 8px 25px;
    font-size: 16px;
    font-weight: 600;
    border: none;
}

.profile-list .nav-menu {
    overflow: auto;
}

.login-modal input.input-form-checkbox {
    display: none;
}

/* Profile css */

.hide_file {
    position: absolute;
    z-index: 1000;
    opacity: 0;
    cursor: pointer;
    left: 50%;
    top: 50px;
    height: 100px;
    width: 100px;
    margin: 0 auto;
    border-radius: 50%;
    transform: translate(-50%, -50%);
}

/* common css */
.wrapper-content {
    padding-top: 6rem;
}

.Toastify__toast-container--top-right {
    margin-top: 5em;
}

/* padding_all_div */
.padding-globle {
    padding: 0px 35px !important;
}

/*  */
/*movies languages overly  */
.language-movie-card {
    position: relative;
    cursor: pointer;
}

.language-movie-card img.language-movie-card-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: 50% 0%;
    border-radius: 4px;
}

.language-movie-overly {
    background-color: #ffcc00;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.25s ease-in,
    bottom 0.25s cubic-bezier(0.33, 0.04, 0.63, 0.93);
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.8);
    margin-top: -30px;
    z-index: 999999;
}

.language-movie-overly a {
    text-decoration: none;
}

.language-movie-card:hover .language-movie-overly {
    visibility: visible;
    opacity: 2;
    animation-name: zoomIn;
    animation-duration: 0.7s;
}

.language-movie-overly .video-player {
    height: 100%;
}

.language-movie-overly .video-player .MuiSvgIcon-root {
    font-size: 4rem;
    color: #ffcc00;
}

/* tooltip */
.copy-text {
    background-color: #f9c131;
    padding: 2px 3px;
    border-radius: 50%;
}

.custom-toolip {
    position: relative;
}

.custom-toolip .tooltiptext {
    visibility: hidden;
    background-color: rgba(216, 212, 212, 0.629);
    color: #0d6efd;
    text-align: center;
    padding: 5px 20px;
    font-size: 12px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    right: 36px;
    top: 6px;
    font-weight: 600;
    /*  */
}

.tooltiptext svg {
    font-size: 16px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.custom-toolip:hover .tooltiptext {
    visibility: visible;
}

/* movie not found css */
.movie-not-found-page .movie-not-found {
    padding: 5.1rem 0px;
    text-align: center;
}

.movie-not-found-page .movie-not-found h2 {
    color: #000;
    font-size: 10rem;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgb(65, 63, 63);
}

.movie-not-found-page .movie-not-found h4 {
    font-size: 4rem;
    font-weight: 600;
    text-shadow: 0px 2px 4px rgb(65, 63, 63);
}

/* iframe */
iframe {
    pointer-events: none;
}

/*  */
.devider-outer {
    position: relative;
}

span.or-devider {
    position: absolute;
    top: 0px;
    right: 45%;
    transform: translate(-50%, -50%);
    padding: 2px;
    height: 26px;
    width: 26px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background: #ffcc00;
    border-radius: 50%;
}

.sign-color {
    background: #0a53be;
    padding: 6px;
    border-radius: 24px;
    border: none;
    font-weight: 600;
}



iframe {
    pointer-events: all;
}

/* movie play */
.watch-main-content {
    padding-top: 5.8rem;
}

#watch .movie-player {
    background-color: #000;
    /* max-height: 80vh; */
}

#watch .movie-player video {
    /* max-height: 80vh; */
    width: 100%;
}

#watch .movie-content-text {
    padding: 20px;
}

#watch .movie-title-sec h3 {
    font-weight: 600;
    margin: 0px;
    text-transform: capitalize;
    padding-bottom: 12px;
    color: #3968b6;
}

#watch .cate-lang-sec span {
    color: #000;
    font-weight: 500;
    margin: 0px 10px;
    line-height: 26px;
    padding: 10px 10px;
    text-transform: capitalize;
}

#watch .cate-lang-sec span:last-child {
    margin-right: 0px;
    padding-right: 0px;
}

#watch .cate-lang-sec span::before {
    content: " ";
    height: 6px !important;
    width: 6px !important;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    margin: 14px -10px;
}

#watch .description {
    border-top: 1px solid #3968b6;
    padding-top: 20px;
}

#watch .description p {
    color: #000;
    margin: 0px;
    font-weight: 600;
    padding: 10px 0px;
    text-transform: capitalize;
}

/*  */
.booking-all-history .card {
    height: 130px;
}

.booking-all-history .card .card-poster,
.booking-all-history .row {
    height: 100%;
}

.booking-all-history .card .card-poster img {
    width: 100%;
    height: 100%;
}

.streamModalOuter .modal-content .modal-header {
    display: none;
}

.str-warning h4 {
    line-height: 38px;
}

.streamModalOuter button.custom-Str-btn {
    border-radius: 22px;
    background-color: #ffcc00;
    border: 1px solid #ffcc00;
    color: #2449ad;
    padding: 6px 50px;
    font-weight: 600;
}

button.custom-Str-btn.out-line-btn {
    background: none;
    color: #ffcc00;
}

.streamModalOuter button.custom-Str-btn:hover {
    box-shadow: 0px 0px 5px #c3bfbfa8;
}

/*  */

.trailer {
    position: absolute;
    top: 0px;
    z-index: 99999;
    background: #ffcc0d;
    transition: 0.5s;
    color: #3968b6;
    padding: 5px 18px;
    border-radius: 30px;
    font-size: 14px;
    margin: 4px 1px;
    font-weight: 500;
}

.referral-code-field input {
    padding: 4px !important;
}
.ref-text{
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
}

.slider-overlay {
    position: absolute;
    top: 20%;
    z-index: 9;
    width: 80%;
    left: 5%;
}

.slider-outer {
    position: relative;
}

.dropdown-content a:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.dropdown-content a:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.flex {
    display: flex;
}

.row {
    flex-direction: row;
}

.grecaptcha-badge {
    right: 0 !important;
    position: unset !important;
    bottom: 0 !important;
}

.container-carousel {
    padding: 0 6% 0 6%;
    width: 100%;
}

.movie-slider-done-icon {
    height: 20px !important;
    width: 20px !important;
    background-color: #ffcc00;
    border-radius: 50%;
    color: white;
}

.rent-text {
    font-size: 15px;
    margin-bottom: 3%;
}

.icon-btn {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* .icon-btn-40 {
    height: 40px;
    width: 40px;
} */

.icon-btn-20 {
    height: 20px;
    width: 20px;
}

.icon-yellow {
    background-color: #ffcc00;
    color: #2449ad;
}

.icon-blue {
    background-color: #2449ad;
    color: white;
}

.icon-white {
    background-color: white;
    color: black;
}

.icon-yellow:hover {
    transform: scale(1.1);
    animation-name: zoomIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.icon-white:hover {
    background-color: #ffcc00;
    color: #2449ad;
    transform: scale(1.1);
    animation-name: zoomIn;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
}

.icon-blue:hover {
    background-color: #0c2d80;
}

.icon-yellow:hover {
    background-color: #cea200;
}

.icon-20 {
    height: 20px !important;
    width: 20px !important;
}

.icon-14 {
    height: 14px !important;
    width: 14px !important;
}


.ml-10px {
    margin-left: 12px;
    margin-top: 4px;
}

.movie-card-my-movie {
    height: 270px;
    box-shadow: 0px 0px 8px #000;
    cursor: pointer;
}

.cursor-pointer {
    cursor: pointer;
}

.full-screen-trailer-modal {
    min-width: 99vw !important;
    margin: 0 !important;
}

.full-screen-trailer-modal .modal-content {
    border: 0 !important;
}

.full-screen-trailer-modal-body {
    position: relative;
}

.full-screen-trailer-close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9999;
}

.full-screen-trailer-player {
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
}

.no-border {
    border: 0;
}

/* ReferPopup.css */

.disable-scroll {
    overflow: hidden;
  }
  

.popup-container {
    position: absolute;
    padding: 0.8rem;
    z-index: 999999999999;
    margin-left: 25%;
    margin-right: 25%;
    border-radius: 0.5rem;
    width: 50%;
    height: 60%;
    opacity: calc(100);
    background: linear-gradient(to right, #5A0223, #1E2744);

}

.title {
    display: flex;


}

.input-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #000000;
    border-radius: 0.375rem;
    outline: none;
    width: 84%;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px !important;
    background-color: #fff;
    /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */

}

.input-field {
    width: 90%;
    border: none;
    text-align: center;
    font-size: 1rem;
    background-color: #fff;


}


.copy-button {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 2px 4px 2px;
    font-weight: 600;
    background-color: #cea200;
    border-radius: 0.375rem;
    cursor: pointer;

}

.popup-close {
    color: #FFCC00;
    display: flex;
    width: 2%;
}

.popup-btwn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    flex-shrink: 0;
    margin-bottom: 0.5rem;


    color: #FFCC00;

    font-family: Quicksand;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;

}

.popup-image {
    width: 620px;
    height: 152px;
    flex-shrink: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}

.popup-para {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    font-family: Quicksand;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    width: 80%;
     margin: auto;
}

.pop-clo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 98%;
}


.icon-popup {

    width: 10%;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    padding-top: 2px;

}

.pop-img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}


.pop-imag {
    display: flex;
    gap: 28px;
    margin-left: 40%;
    margin-right: 40%;
    margin-top: 8px;

}


.bann-movi {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: 100% 0%;

}
.signupbtn{
    width: 50%;
    height:35px;
    padding: 8px 10px;
    gap: 10px;
    border-radius: 10px;
    background: #FFCC00;
    border: none;
    margin-bottom: 30%;
}

.signin-width{
    /* width: 451px; */
    
    /* width: 451px; */
}


/* .login-xl-btn {
    color: white;
    background-color: #ffcc00 !important;
}

.login-xl-btn:hover{
    background-color: #2449ad !important;
}


@media screen and (min-device-width: 641px) and (max-device-width: 820px) {
    .main-swiper-slider .slider-banner {
        width: 100%;
        height: 450px;
        
        position: relative;
    }
    
    .main-swiper-slider .slider-banner .swiper-carousel-main-banner {
        width: 100%;
        height: 100%;
        object-fit: fill;
        object-position: 100% 0%;
        padding-top: 1%
    }
    
    .slider-content {
        width: 100%;
        position: absolute;
        display: flex;
        align-items: center;
        top: 0px;
        height: 100%;
        margin: auto;
        color: #fff;
        padding: 60px 0px 60px 0px;
        background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.9023984593837535) 10%,
                rgba(0, 0, 0, 0.865983893557423) 10%,
                rgba(0, 0, 0, 0.0032387955182072714) 90%
        );
    }
    
    /* .movie-detail.movie-content-section {
        margin-left: 2rem;
        padding-top: 80px;
    } */
    
    .movie-detail h1 {
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 8px;
      
        text-transform: capitalize;
        color: #fff;
    }
    
    .section-heading-band h3 {
        background-color: #ffcc00;
        border-radius: 30px;
        color:#5C0223;
        font-size: 26px;
        font-weight: 500;
        line-height: 2rem;
        margin: 15px 0px 15px 0px;
        padding: 0px 0px;
        text-align: center;
        width: 45%;
        margin: auto;
        margin-bottom: 30px;
        margin-top: 20px;
    
    }
    
    .icon-btn-40 {
        height: 28px;
        width: 28px;
       
    }
    
    
    
    @media screen and (min-device-width: 360px) and (max-device-width: 480px){
        .signin-width{
            width: 251px;
        }        
    }    
    
    
    





@media screen and (min-device-width: 505px) and (max-device-width: 620px) {
.main-swiper-slider .slider-banner {
    width: 100%;
    height: 380px;
    /*  */
    position: relative;
}

.main-swiper-slider .slider-banner .swiper-carousel-main-banner {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: 100% 0%;
    padding-top: 0%
}

.slider-content {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    top: 0px;
    height: 100%;
    margin: auto;
    color: #fff;
    padding: 60px 0px 60px 0px;
    background: linear-gradient(
            90deg,
            rgba(0, 0, 0, 0.9023984593837535) 10%,
            rgba(0, 0, 0, 0.865983893557423) 10%,
            rgba(0, 0, 0, 0.0032387955182072714) 90%
    );
}

/* .movie-detail.movie-content-section {
    margin-left: 2rem;
    padding-top: 80px;
} */

.movie-detail h1 {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 8px;
  
    text-transform: capitalize;
    color: #fff;
}

.section-heading-band h3 {
    background-color: #ffcc00;
    border-radius: 30px;
    color: #5C0223;
    font-size: 26px;
    font-weight: 500;
    line-height: 2rem;
    margin: 15px 0px 15px 0px;
    padding: 0px 0px;
    text-align: center;
    width: 50%;
    margin: auto;
    margin-bottom: 30px;
    margin-top: 20px;

}

.icon-btn-40 {
    height: 30px;
    width: 30px !important;
    /* margin-left: 4px; */
    /* margin-top: 2px; */
   
}


.margleft{
    margin-left: 10px;
    margin-top: 2px;
    height: 34px;
   width: 34px !important;
  }
  




}




@media screen and (min-device-width: 460px) and (max-device-width: 504px) {
    .main-swiper-slider .slider-banner {
        width: 100%;
        height: 350px;
        /*  */
        position: relative;
    }
    
    .main-swiper-slider .slider-banner .swiper-carousel-main-banner {
        width: 100%;
        height: 100%;
        object-fit: fill;
        object-position: 100% 0%;
        padding-top: 0%
    }
    
    .slider-content {
        width: 100%;
        position: absolute;
        display: flex;
        top: 0px;
        height: 100%;
        margin: auto;
        color: #fff;
        padding: 60px 0px 60px 0px;
        background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.9023984593837535) 10%,
                rgba(0, 0, 0, 0.865983893557423) 10%,
                rgba(0, 0, 0, 0.0032387955182072714) 90%
        );
    }
    
    /* .movie-detail.movie-content-section {
        margin-left: 2rem;
        padding-top: 80px;
    } */
    
    .movie-detail h1 {
        font-size: 3rem;
        font-weight: 600;
       text-transform: capitalize;
        color: #fff;
    }

    .rent-text {
        font-size: 15px;
        margin-bottom: 3%;
        width: 90%;
    }

    

    .icon-btn {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 5px;
        height: 5px;
    }
    
    .icon-btn-40 {
        height: 20px;
        width: 20px;
    }
    
    .icon-btn-20 {
        height: 10px;
        width: 10px;
    }


    .icon-20 {
        height: 12px !important;
        width: 12px !important;
    }
    

    .icon-yellow {
        background-color: #ffcc00;
        color: #2449ad;
        font-size: 2px !important;
    }
    
    .icon-blue {
        background-color: #2449ad;
        color: white;
    }
    
    .icon-white {
        background-color: white;
        color: black;
    }


    .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        /* display: none !important; */
    }
    
    

    

    .section-heading-band h3 {
        background-color: #ffcc00;
        border-radius: 30px;
        color: #5C0223;
        font-size: 17px;
        font-weight: 500;
        line-height: 2rem;
        margin: 15px 0px 15px 0px;
        padding: 0px 0px;
        text-align: center;
        width: 40%;
        margin: auto;
        margin-bottom: 30px;
        margin-top: 20px;
        
    }


    .icon-btn-40 {
        height: 26px;
        width: 26px;
        margin-left: 4px;
        /* margin-top: 2px; */
       
    }

    .movie-slider-done-icon {
        height: 10px !important;
        width: 10px !important;
        background-color: #ffcc00;
        border-radius: 50%;
        color: white;
    }

    .ml-10px {
        margin-left: 4px;
    }

    .margleft{
        margin-top: 3px;
        margin-left: 6px !important; 
        height: 30px;
        width: 30px; 
    }

    

    
    }

@media screen and (min-device-width: 360px) and (max-device-width: 361px) {
 .movie-icon-button button {
    padding: 2px 2px !important;
 }
}
@media screen and (min-device-width: 360px) and (max-device-width: 460px) {

        .movie-slider-done-icon {
            height: 8px !important;
            width: 8px !important;
            background-color: #ffcc00;
            border-radius: 50%;
            color: white;
        }
        

        .icon-btn-40 {
            height: 22px;
            width: 22px;
           
        }

        .main-swiper-slider .slider-banner {
            width: 100%;
            height: 350px;
            /*  */
            position: relative;
        }
        
        .main-swiper-slider .slider-banner .swiper-carousel-main-banner {
            width: 100%;
            height: 100%;
            object-fit: fill;
            object-position: 100% 0%;
            padding-top: 0%
        }
        
        .slider-content {
            width: 100%;
            position: absolute;
            display: flex;
            top: 0px;
            height: 100%;
            margin: auto;
            color: #fff;
            padding: 60px 0px 60px 0px;
            background: linear-gradient(
                    90deg,
                    rgba(0, 0, 0, 0.9023984593837535) 10%,
                    rgba(0, 0, 0, 0.865983893557423) 10%,
                    rgba(0, 0, 0, 0.0032387955182072714) 90%
            );
        }
        
        /* .movie-detail.movie-content-section {
            margin-left: 2rem;
            padding-top: 80px;
        } */
        
        .movie-detail h1 {
            font-size: 26px;
            font-weight: 600;
           text-transform: capitalize;
            color: #fff;
        }

        

        .rent-text {
            font-size: 15px;
            margin-bottom: 3%;
            width: 72%;
        }

        .ml-10px {
            margin-left: 5px;
        }

        .movie-icon-button button {
            border-radius: 22px;
            background-color: #ffcc00;
            border: 6px solid #ffcc00;
            /*color: #2449ad;*/
            padding: 2px 2px;
            font-size: 6px;
            font-weight: 600;
            color: #032152 !important;
        }

        .movie-information button {
            border-radius: 22px;
            background-color: #fff;
            border: 6px solid #fff;
            color: #323232;
            padding: 2px 2px;
            font-size: 10px;
            font-weight: 600;
        }

        .rent-option-button button {
            margin-right: 6px;
        }


        #sliderpoints-wrapper .share-movie-btn button {
             display: flex;
            font-weight: 600;
            color: #032152 !important;
            margin-left: 4px;
        }

        .movie-EventAttributes ul {
            display: flex;
            list-style: none;
            padding: 0;
            margin: 0;
            width: 126px;
        }
        
        .movie-EventAttributes li {
            padding: 4px 0px;
            font-size: 16px;
            font-weight: 800;
            margin-right: 10px;
        }
        
        .movie-EventAttributes h6 {
            color: #fff;
            text-decoration: none;
            font-size: 10px;
        }
        
        .movie-EventAttributes li svg.MuiSvgIcon-root {
            color: #ffb800;
            font-size: 16px;
        }

        .section-heading-band h3 {
            background-color: #ffcc00;
            border-radius: 30px;
            color: #5C0223;
            font-size: 20px;
            font-weight: 500;
            line-height: 2rem;
            margin: 15px 0px 15px 0px;
            padding: 0px 0px;
            text-align: center;
            width: 50% !important;
            margin: auto;
            margin-bottom: 30px;
            margin-top: 20px;
           
        }


        .icon-btn {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5px;
            height: 5px;
        }
        
        .icon-btn-40 {
            height: 27px;
            width: 27px;
        }
        
        .icon-btn-20 {
            height: 10px;
            width: 10px;
        }


        .icon-20 {
            height: 10px !important;
            width: 10px !important;
        }

        .margleft{
            margin-left: 6px;
            margin-top: 2px;
        }
        

        .icon-yellow {
            background-color: #ffcc00;
            color: #2449ad;
            font-size: 2px !important;
        }
        
        .icon-blue {
            background-color: #2449ad;
            color: white;
        }
        
        .icon-white {
            background-color: white;
            color: black;
        }

        .margleft{
            margin-top: 3px;
            margin-left: 6px !important; 
            height: 29px;
            width: 29px; 
        }
    


        .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            /* display: none !important; */
        }
        
        


        .dhakad-rating-sec {
            position: relative;
        }
        
        .dhakad-rating-sec .dhakad-rating-title-sec {
            display: flex;
        }
        
        .dhakad-rating-sec .dhakad-rating-title-sec h6 {
            font-size: 10px;
            font-weight: 700;
            margin-right: 10px;
            line-height: 26px;
            color: #fff;
        }
        
        .dhakad-rating-title-sec img {
            width: 35px;
            height: 35px;
            object-fit: fill;
            margin-top: 6px;
        }
        
        .dhakad-rating-sec span {
            font-size: 8px;
            font-weight: 600;
            background: #ffcc00;
            color: #2449ad;
            height: 12px !important;
            width: 12px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center !important;
            margin-left: 10px;
            top: -20px !important;
            border-radius: 18px;
            cursor: pointer;
            margin-top: 6px;
            
            
        }
        
        .dhakad-rating-tooltip {
            display: none;
            position: absolute;
            bottom: 30px;
            left: 20%;
           
           
        }

        
        .dhakad-rating-tooltip-btn:hover + .dhakad-rating-tooltip {
            display: block;
        }
        
        .dhakad-rating-tooltip span {
            padding: 5px 5px;
            border-radius: 0px;
            
        }
        

        
        }


@media screen and (min-device-width: 220px) and (max-device-width: 259px) {
     .section-heading-band h3 {
         background-color: #ffcc00;
        border-radius: 30px;
        color: #5C0223;
        font-size: 8px;
        font-weight: 500;
        text-align: center;
          width: 46%;
          margin: auto;
           margin-bottom: 20px;
            }

            .icon-btn-40 {
                height: 16px;
                width: 16px;
               
            }

            .ml-10px {
                margin-left: 1px;
            }
    

            .movie-slider-done-icon {
                height: 6px !important;
                width: 6px !important;
                background-color: #ffcc00;
                border-radius: 50%;
                color: white;
            }
            

            .section-heading-band h3 {
                background-color: #ffcc00;
                border-radius: 30px;
                color: #5C0223;
                font-size: 20px;
                font-weight: 500;
                line-height: 2rem;
                margin: 15px 0px 15px 0px;
                padding: 0px 0px;
                text-align: center;
                width: 35%;
                margin: auto;
                margin-bottom: 16px;
                margin-top: 16px;
               
            }



            
        
        }
    

@media screen and (min-device-width: 260px) and (max-device-width: 259px) {

            .icon-btn-40 {
                height: 18px;
                width: 18px;
               
            }

            .ml-10px {
                margin-left: 2px;
            }
    

            .section-heading-band h3 {
                background-color: #ffcc00;
            border-radius: 30px;
            color: #5C0223;
            font-size: 10px;
            font-weight: 500;
            text-align: center;
            width: 55% !important;
            margin: auto;
            margin-bottom: 20px;
            margin-top: 15px;
            }

            .main-swiper-slider .slider-banner {
                width: 100%;
                height: 350px;
                /*  */
                position: relative;
            }
            
            .main-swiper-slider .slider-banner .swiper-carousel-main-banner {
                width: 100%;
                height: 100%;
                object-fit: fill;
                object-position: 100% 0%;
                padding-top: 0%
            }
            
            .slider-content {
                width: 100%;
                position: absolute;
                display: flex;
                top: 0px;
                height: 100%;
                margin: auto;
                color: #fff;
                padding: 60px 0px 60px 0px;
                background: linear-gradient(
                        90deg,
                        rgba(0, 0, 0, 0.9023984593837535) 10%,
                        rgba(0, 0, 0, 0.865983893557423) 10%,
                        rgba(0, 0, 0, 0.0032387955182072714) 90%
                );
            }
            
            /* .movie-detail.movie-content-section {
                margin-left: 2rem;
                padding-top: 80px;
            } */
            
            .movie-detail h1 {
                font-size: 26px;
                font-weight: 600;
               text-transform: capitalize;
                color: #fff;
            }

            

        .icon-btn {
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 5px;
            height: 5px;
        }
        
        .icon-btn-40 {
            height: 20px;
            width: 20px;
        }
        
        .icon-btn-20 {
            height: 8px;
            width: 8px;
        }


        .icon-20 {
            height: 8px !important;
            width: 8px !important;
        }
        

        .icon-yellow {
            background-color: #ffcc00;
            color: #2449ad;
            font-size: 2px !important;
        }
        
        .icon-blue {
            background-color: #2449ad;
            color: white;
        }
        
        .icon-white {
            background-color: white;
            color: black;
        }


        .swiper-pagination-bullet {
            width: 6px;
            height: 6px;
            /* display: none !important; */
        }
        
        
            

            .rent-text {
                font-size: 10px;
                margin-bottom: 3%;
                width: 204px;
                font-weight: 300;
            }

            .movie-icon-button button {
                border-radius: 22px;
                background-color: #ffcc00;
                border: 1px solid #ffcc00;
                /*color: #2449ad;*/
                /* padding: 2px 2px; */
                font-size: 6px;
                font-weight: 600;
                color: #032152 !important;
            }

            .rent-option-button button {
                margin-right: 6px;
            }
            

          

    }
        

@media screen and (min-device-width: 1920px) and (max-device-width: 2200px) {
                .section-heading-band h3 {
                    background-color: #ffcc00;
                    border-radius: 30px;
                    color: #5C0223;
                    font-size: 26px;
                    font-weight: 500;
                    line-height: 2rem;
                    margin: 15px 0px 15px 0px;
                    padding: 0px 0px;
                    text-align: center;
                    width: 15%;
                    margin: auto;
                    margin-bottom: 30px;
                    margin-top: 20px;
    
                }

                .icon-btn-40 {
                    height: 40px !important;
                    width: 12px !important;
                    padding-left: 20px !important;
                    padding-right: 20px !important;
                    margin-left: 8px;
                    margin-top: 2px;
                }

                .share-movie-btn button {
                    font-size: 16px !important;
                    padding: 8px 18px;
                    margin-left: 8px;
                }
            
            }


            @media screen and (min-device-width: 320px) and (max-device-width: 360px) {
                .main-swiper-slider .slider-banner {
                    width: 100%;
                    height: 350px;
                    position: relative;
                }

                .movie-detail h1 {
                    font-size: 24px;
                    font-weight: 600;
                    text-transform: capitalize;
                    color: #fff;
                }
            
            }






                @media screen and (min-device-width: 1700px) and (max-device-width: 1919px) {
                    .section-heading-band h3 {
                        background-color: #ffcc00;
                        border-radius: 30px;
                        color: #5C0223;
                        font-size: 26px;
                        font-weight: 500;
                        line-height: 2rem;
                        margin: 15px 0px 15px 0px;
                        padding: 0px 0px;
                        text-align: center;
                        width: 18%;
                        margin: auto;
                        margin-bottom: 30px;
                        margin-top: 20px;
                        padding-top: 14px;
                        padding-bottom: 14px;
    
                    }

                    .icon-btn-40 {
                        height: 40px !important;
                        width: 12px !important;
                        padding-left: 20px !important;
                        padding-right: 20px !important;
                        /* margin-left: 8px; */
                        /* margin-top: 2px; */
                    }

                    .share-movie-btn button {
                        font-size: 16px !important;
                        padding: 8px 18px;
                        margin-left: 8px;
                    }
                
                
                
                }

                @media screen and (min-device-width: 1481px) and (max-device-width: 1699px) {
                    .icon-btn-40 {
                        height: 40px !important;
                        width: 12px !important;
                        padding-left: 20px !important;
                        padding-right: 20px !important;
                        margin-left: 8px;
                        /* margin-top: 2px; */
                    }  

                    .share-movie-btn button {
                        font-size: 16px !important;
                        padding: 8px 18px;
                        margin-left: 8px;
                    }
                }


    

                    @media screen and (min-device-width: 1441px) and (max-device-width: 1699px) {
                        .section-heading-band h3 {
                            background-color: #ffcc00;
                            border-radius: 30px;
                            color: #5C0223;
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 2rem;
                            margin: 15px 0px 15px 0px;
                            padding: 0px 0px;
                            text-align: center;
                            width: 25%;
                            margin: auto;
                            margin-bottom: 30px;
                            margin-top: 20px;
                            padding-top: 12px;
                            padding-bottom: 12px;
        
                        }}
        
    
                    @media screen and (min-device-width: 1220px) and (max-device-width: 1440px) {
                        .section-heading-band h3 {
                            background-color: #ffcc00;
                            border-radius: 30px;
                            color: #5C0223;
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 2rem;
                            margin: 15px 0px 15px 0px;
                            padding: 0px 0px;
                            text-align: center;
                            width: 17%;
                            margin: auto;
                            margin-bottom: 30px;
                            margin-top: 20px;
    
                        }}



                        @media screen and (min-device-width: 800px) and (max-device-width: 1024px) {
                        .registration-content button {
                            padding: 8px 10px;
                            font-size: 26px !important;
                        }
                    }
    

                    @media screen and (min-device-width: 600px) and (max-device-width: 799px) {
                        .registration-content button {
                            padding: 8px 9px !important;
                            font-size: 24px !important;
                        }
                    }


                    @media (min-width: 320px) and (max-width: 380px)  {
                       
                      .popup-container {
                        /* position: absolute; */
                        padding: 0.8rem;
                        z-index: 999999999999;
                        margin-left: 10%;
                        margin-right: 10%;
                        border-radius: 0.5rem;
                        width: 80%;
                        /* margin: auto; */
                        height: 384px;
                        opacity: calc(100);
                        background: linear-gradient(to right, #5A0223, #1E2744);
                    
                    }  

 
                    
.input-container {
    display: flex;
    flex-direction: row;
    border: 1px solid #000000;
    border-radius: 6px;
    outline: none;
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px !important;
    background-color: #fff;
    padding-top: 4px;
    padding-bottom: 4px;

    /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */

}

.input-field {
    width: 84%;
    border: none;
    text-align: center;
    font-size: 7px;
    background-color: #fff;


}


.copy-button {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 2px 4px 2px;
    font-weight: 600;
    background-color: #cea200;
    border-radius: 0.375rem;
    cursor: pointer;

}

.popup-close {
    color: #FFCC00;
    display: flex;
    width: 2%;
}

.popup-btwn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    margin-bottom: 0.5rem;
    color: #FFCC00;
    font-family: Quicksand;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    width: 76%;
    margin: auto;
    text-align: center;

}

.popup-image {
    width: 100%;
    height: 100px;
    flex-shrink: 0;
    margin-top: 30px;
    margin-bottom: 30px;
}

.popup-para {
    display: flex;
    flex-direction: column;
    color: white;
    text-align: center;
    font-family: Quicksand;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    justify-content: center;
    width: 100%;
     margin: auto;
}

.pop-clo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 98%;
}


.icon-popup {

    width: 16%;
    background-color: #fff;
    /* padding-top: 4px; */
    padding-left: 8px;
    color: #A1A1A1;
    font-size: 12px;

}

.pop-img {
    width: 28px;
    height: 28px;
    flex-shrink: 0;
}


.pop-imag {
    display: flex;
    justify-content: center;
    gap: 28px;
    margin-top: 8px;

}

                        
                      
                    }
                    @media (min-width: 380px) and (max-width: 420px)  {
                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 10%;
                            margin-right: 10%;
                            border-radius: 0.5rem;
                            width: 80%;
                            /* margin: auto; */
                            height: 386px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  
    
     
                        
    .input-container {
        display: flex;
        flex-direction: row;
        border: 1px solid #000000;
        border-radius: 6px;
        outline: none;
        width: 86%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px !important;
        background-color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
    
        /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
    
    }
    
    .input-field {
        width: 90%;
        border: none;
        text-align: center;
        font-size: 8px;
        background-color: #fff;

                    @media screen and (min-device-width: 641px) and (max-device-width: 766px) {
                        .icon-yellow {
                            background-color: #ffcc00;
                            color: #2449ad;
                            height: 34px !important;
                            width: 34px !important;
                        }
                        
                        .icon-blue {
                            background-color: #2449ad;
                            color: white;
                        }
                        
                        .icon-white {
                            background-color: white;
                            color: black;
                            height: 36px !important;
                            width: 36px !important;
                        } 

                        .ml-10px {
                            margin-left: 10px;
                            margin-top: 2px;
                        } 
                    }



@media screen and (min-device-width: 767px) and (max-device-width: 1100px) {
                        .icon-yellow {
                            background-color: #ffcc00;
                            color: #2449ad;
                            height: 36px !important;
                            width: 36px !important;
                        }
                        
                        .icon-blue {
                            background-color: #2449ad;
                            color: white;
                        }
                        
                        .icon-white {
                            background-color: white;
                            color: black;
                            height: 36px !important;
                            width: 36px !important;
                        } 

                        .ml-10px {
                            margin-left: 12px;
                            margin-top: 2px;
                        } 
                    }


@media screen and (min-device-width: 1100px) and (max-device-width: 1900px) {
          
.icon-btn {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-btn-40 {
    height: 40px;
    width: 40px;
}

.icon-btn-20 {
    height: 20px;
    width: 20px;
}

.icon-yellow {
    background-color: #ffcc00;
    color: #2449ad;
    height: 38px !important;
    width: 38px !important;
}

.icon-blue {
    background-color: #2449ad;
    color: white;
}

.icon-white {
    background-color: white;
    color: black;
    height: 38px !important;
    width: 38px !important;
}

.icon-white:hover {
    background-color: #ffcc00;
    color: #2449ad;
}

.icon-blue:hover {
    background-color: #0c2d80;
}

.icon-yellow:hover {
    background-color: #cea200;
}

.icon-20 {
    height: 20px !important;
    width: 20px !important;
}

.icon-14 {
    height: 14px !important;
    width: 14px !important;
}


.ml-10px {
    margin-left: 12px;
    margin-top: 2px;
}    }  

@media screen and (min-device-width: 425px) and (max-device-width: 460px) {
    .form-select{
        padding: 7px 10px !important;
    }
    .icon-btn-40 {
        height: 23px !important;
        width: 23px !important;
    }
    
    .icon-btn-20 {
        height: 22px !important;
        width: 22px !important;
    }

   
}


@media screen and (min-device-width: 461px) and (max-device-width: 500px) {
    .form-select{
        padding: 7px 10px !important;
    }
    .icon-btn-40 {
        height: 25px !important;
        width: 25px !important;
    }
    
    .icon-btn-20 {
        height: 22px !important;
        width: 22px !important;
    }

    .ml-10px {
        margin-left: 6px;
       
    }   

}


@media screen and (min-device-width: 501px) and (max-device-width: 600px) {

    .icon-btn-40 {
        height: 28px !important;
        width: 28px !important;
    }
    
    .icon-btn-20 {
        height: 28px !important;
        width: 28px !important;
    }

    .icon-yellow {
        background-color: #ffcc00;
        color: #2449ad;
        height: 32px !important;
        width: 32px !important;
    }
    
    .icon-blue {
        background-color: #2449ad;
        color: white;
    }
    
    .icon-white {
        background-color: white;
        color: black;
        height: 32px !important;
        width: 32px !important;
    }
    

    .ml-10px {
        margin-left: 6px;
        margin-top: 8px !important;
       
    }   

}


@media screen and (min-device-width: 768px) {
    .movie-information-outer {
        align-items: center;
        display: flex;
        margin-top: 20px;
        
    }
}


    
    
    }
    
    
    .copy-button {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px 4px 2px;
        font-weight: 600;
        background-color: #cea200;
        border-radius: 0.375rem;
        cursor: pointer;
    
    }
    
    .popup-close {
        color: #FFCC00;
        display: flex;
        width: 2%;
    }
    
    .popup-btwn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 0.5rem;
        color: #FFCC00;
        font-family: Quicksand;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        width: 70%;
        margin: auto;
        text-align: center;
    
    }
    
    .popup-image {
        width: 100%;
        height: 100px;
        flex-shrink: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .popup-para {
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        font-family: Quicksand;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        justify-content: center;
        width: 90%;
         margin: auto;
    }
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 15%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }

    .input-field {
        width: 85%;
        border: none;
        text-align: center;
        font-size: 7px;
        background-color: #fff;
    
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }
    
                            
                          
    
                      
                    }
                    
                    @media (min-width: 420px) and (max-width: 480px)  {
                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 10%;
                            margin-right: 10%;
                            border-radius: 0.5rem;
                            width: 80%;
                            /* margin: auto; */
                            height: 390px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  
    
     
                        
    .input-container {
        display: flex;
        flex-direction: row;
        border: 1px solid #000000;
        border-radius: 6px;
        outline: none;
        width: 80%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px !important;
        background-color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
    
        /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
    
    }
    
    .input-field {
        width: 85%;
        border: none;
        text-align: center;
        font-size: 8px;
        background-color: #fff;
    
    
    }
    
    
    .copy-button {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px 4px 2px;
        font-weight: 600;
        background-color: #cea200;
        border-radius: 0.375rem;
        cursor: pointer;
    
    }
    
    .popup-close {
        color: #FFCC00;
        display: flex;
        width: 2%;
    }
    
   
    .popup-image {
        width: 100%;
        height: 100px;
        flex-shrink: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 15%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }
    
                            
                          
    
                      
                    }


                    @media (min-width: 420px) and (max-width: 468px)  {
                    .popup-para {
                        display: flex;
                        flex-direction: column;
                        color: white;
                        text-align: center;
                        font-family: Quicksand;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        justify-content: center;
                        width: 80%;
                         margin: auto;
                    }

                    .popup-btwn {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        margin-bottom: 0.5rem;
                        color: #FFCC00;
                        font-family: Quicksand;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        width: 60%;
                        margin: auto;
                        text-align: center;
                    
                    }
                    

                }
                    

                    @media (min-width: 469px) and (max-width: 480px)  {
                    .popup-para {
                        display: flex;
                        flex-direction: column;
                        color: white;
                        text-align: center;
                        font-family: Quicksand;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                        justify-content: center;
                        width: 76%;
                         margin: auto;
                    }

                    .popup-btwn {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-shrink: 0;
                        margin-bottom: 0.5rem;
                        color: #FFCC00;
                        font-family: Quicksand;
                        font-size: 15px;
                        font-style: normal;
                        font-weight: 600;
                        width: 52%;
                        margin: auto;
                        text-align: center;
                    
                    }
                }
                    
                  
                    
                    @media (min-width: 481px) and (max-width: 582px)  {
                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 10%;
                            margin-right: 10%;
                            border-radius: 0.5rem;
                            width: 80%;
                            /* margin: auto; */
                            height: 420px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  
    
     
                        
    .input-container {
        display: flex;
        flex-direction: row;
        border: 1px solid #000000;
        border-radius: 6px;
        outline: none;
        width: 84%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px !important;
        background-color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
    
        /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
    
    }
    
    .input-field {
        width: 86%;
        border: none;
        text-align: center;
        font-size: 10px;
        background-color: #fff;
    
    
    }
    
    
    .copy-button {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px 4px 2px;
        font-weight: 600;
        background-color: #cea200;
        border-radius: 0.375rem;
        cursor: pointer;
    
    }
    
    .popup-close {
        color: #FFCC00;
        display: flex;
        width: 2%;
    }
    
    .popup-btwn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 0.5rem;
        color: #FFCC00;
        font-family: Quicksand;
        font-size: 19px;
        font-style: normal;
        font-weight: 600;
        width: 70%;
        margin: auto;
        text-align: center;
    
    }
    
    .popup-image {
        width: 100%;
        height: 100px;
        flex-shrink: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .popup-para {
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        font-family: Quicksand;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        justify-content: center;
        width: 80%;
         margin: auto;
    }
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 14%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }
       }


       @media screen and (min-device-width: 460px) and (max-device-width: 540px) {
        .movie-information button {
            background-color: #fff;
            border: 6px solid #fff;
            border-radius: 22px;
            color: #323232;
            font-size: 16px;
            font-weight: 600;
            padding: 6px 10px !important;
        }
    }
                    
                    
                    @media (min-width: 583px) and (max-width: 644px)  {
                       
                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 10%;
                            margin-right: 10%;
                            border-radius: 0.5rem;
                            width: 80%;
                            /* margin: auto; */
                            height: 420px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  
    
     
                        
    .input-container {
        display: flex;
        flex-direction: row;
        border: 1px solid #000000;
        border-radius: 6px;
        outline: none;
        width: 80%;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 20px !important;
        background-color: #fff;
        padding-top: 4px;
        padding-bottom: 4px;
    
        /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
    
    }
    
    .input-field {
        width: 86%;
        border: none;
        text-align: center;
        font-size: 11px;
        background-color: #fff;
    
    
    }
    
    
    .copy-button {
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 4px 2px 4px 2px;
        font-weight: 600;
        background-color: #cea200;
        border-radius: 0.375rem;
        cursor: pointer;
    
    }
    
    .popup-close {
        color: #FFCC00;
        display: flex;
        width: 2%;
    }
    
    .popup-btwn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        margin-bottom: 0.5rem;
        color: #FFCC00;
        font-family: Quicksand;
        font-size: 21px;
        font-style: normal;
        font-weight: 600;
        width: 60%;
        margin: auto;
        text-align: center;
    
    }
    
    .popup-image {
        width: 100%;
        height: 110px;
        flex-shrink: 0;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    
    .popup-para {
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        font-family: Quicksand;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        justify-content: center;
        width: 84%;
         margin: auto;
    }
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 14%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }
    
    
                      
                    }
                    
                 
                    
                    
                    @media (min-width: 644px) and (max-width: 825px)  {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            width: 70%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 80%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 80%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 6px;
                            padding-bottom: 6px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 14%;
                            margin-right: 14%;
                            border-radius: 0.5rem;
                            width: 72%;
                            /* margin: auto; */
                            height: 430px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 120px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

   
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 14%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }

    .input-field {
        width: 86%;
        border: none;
        text-align: center;
        font-size: 11px;
        background-color: #fff;
    }
    
                        }  


                                     
                    @media (min-width: 825px) and (max-width: 1025px)  {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 22px;
                            font-style: normal;
                            font-weight: 600;
                            width: 60%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 78%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 78%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 12px;
                            padding-bottom: 12px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            /* margin: auto; */
                            height: 440px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 120px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

   
    
    .pop-clo {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 98%;
    }
    
    
    .icon-popup {
    
        width: 13%;
        background-color: #fff;
        /* padding-top: 4px; */
        padding-left: 8px;
        color: #A1A1A1;
        font-size: 13px;
    
    }
    
    .pop-img {
        width: 28px;
        height: 28px;
        flex-shrink: 0;
    }
    
    
    .pop-imag {
        display: flex;
        justify-content: center;
        gap: 28px;
        margin-top: 8px;
    
    }

    .input-field {
        width: 87%;
        border: none;
        text-align: center;
        font-size: 12px;
        background-color: #fff;
    }

   
                        }  
                        
                       
                        @media (min-width: 1025px) and (max-width: 1212px)  {
           
                            .popup-btwn {
                                display: flex;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                flex-shrink: 0;
                                margin-bottom: 0.5rem;
                                color: #FFCC00;
                                font-family: Quicksand;
                                font-size: 30px;
                                font-style: normal;
                                font-weight: 600;
                                width: 60%;
                                margin: auto;
                                text-align: center;
                            
                            }
                            
                            .popup-para {
                                display: flex;
                                flex-direction: column;
                                color: white;
                                text-align: center;
                                font-family: Quicksand;
                                font-size: 17px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                justify-content: center;
                                width: 70%;
                                 margin: auto;
                            }
    
                            .input-container {
                                display: flex;
                                flex-direction: row;
                                border: 1px solid #000000;
                                border-radius: 6px;
                                outline: none;
                                width: 70%;
                                margin: auto;
                                margin-top: 20px;
                                margin-bottom: 20px !important;
                                background-color: #fff;
                                padding-top: 14px;
                                padding-bottom: 14px;
                            
                                /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                            
                            }

                           
    
                            .popup-container {
                                /* position: absolute; */
                                padding: 0.8rem;
                                z-index: 999999999999;
                                margin-left: 20%;
                                margin-right: 20%;
                                border-radius: 0.5rem;
                                width: 60%;
                                /* margin: auto; */
                                height: 520px;
                                opacity: calc(100);
                                background: linear-gradient(to right, #5A0223, #1E2744);
                            
                            }  
    
                            .popup-image {
                                width: 90%;
                                margin: auto;
                                display: flex;
                                height: 140px;
                                flex-shrink: 0;
                                margin-top: 30px;
                                margin-bottom: 30px;
                            }


                            
                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 13px;
                            background-color: #fff;
                        }
                            

                            .icon-popup {
                                width: 13%;
                                background-color: #fff;
                                /* padding-top: 4px; */
                                padding-left: 8px;
                                color: #A1A1A1;
                                font-size: 13px;
                            }

                            .pop-img {
                                width: 36px;
                                height: 36px;
                                flex-shrink: 0;
                            }
    
                            .pop-imag {
                                display: flex;
                                gap: 28px;
                                margin-left: 40%;
                                margin-right: 40%;
                                margin-top: 22px;
                            }
    

                            }  
                            
                           
        
                 
    
                    @media (min-width: 1212px) and (max-width: 1360px)  {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 60%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 14px;
                            padding-bottom: 14px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            /* margin: auto; */
                            height: 530px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 150px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }


                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 14px;
                            background-color: #fff;
                        }


                        .icon-popup {
                            width: 13%;
                            background-color: #fff;
                            /* padding-top: 4px; */
                            padding-left: 8px;
                            color: #A1A1A1;
                            font-size: 15px;
                        }

                        .pop-img {
                            width: 38px;
                            height: 38px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 24px;
                        }

                    }
                    
    
                    @media (min-width: 1360px) and (max-width: 1492px)  {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 55%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 18px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            /* margin: auto; */
                            height: 556px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 160px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 16px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 13%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            font-size: 20px;
                            padding-left: 8px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }
                    
    
                    @media (min-width: 1492px) and (max-width: 1634px)  {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 50%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            height: 600px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 200px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 18px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 13%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            padding-left: 8px;
                            font-size: 20px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }
                    
    
                    @media (min-width: 1634px) and (max-width: 1700px)   {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 45%;
                            margin: auto;
                            text-align: center;
                        
                        }
                        
                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            height: 600px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 90%;
                            margin: auto;
                            display: flex;
                            height: 200px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 20px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 13%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            padding-left: 8px;
                            font-size: 20px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }
                    

                    @media (min-width: 1700px) and (max-width: 1874px)   {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 45%;
                            margin: auto;
                            text-align: center;
                        
                        }

                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 20%;
                            margin-right: 20%;
                            border-radius: 0.5rem;
                            width: 60%;
                            height: 600px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 80%;
                            margin: auto;
                            display: flex;
                            height: 200px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 88%;
                            border: none;
                            text-align: center;
                            font-size: 21px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 12%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            padding-left: 8px;
                            font-size: 20px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }
                        
                    @media (min-width: 1874px) and (max-width: 2090px)   {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 40%;
                            margin: auto;
                            text-align: center;
                        
                        }

                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 25%;
                            margin-right: 25%;
                            border-radius: 0.5rem;
                            width: 50%;
                            height: 600px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 80%;
                            margin: auto;
                            display: flex;
                            height: 200px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 88%;
                            border: none;
                            text-align: center;
                            font-size: 20px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 12%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            font-size: 20px;
                            padding-left: 8px;
                            padding-left: 4px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }
                        
    
                    @media (min-width: 2090px) and (max-width: 2300px)   {
                        .popup-btwn {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            flex-shrink: 0;
                            margin-bottom: 0.5rem;
                            color: #FFCC00;
                            font-family: Quicksand;
                            font-size: 30px;
                            font-style: normal;
                            font-weight: 600;
                            width: 38%;
                            margin: auto;
                            text-align: center;
                        
                        }

                        .popup-para {
                            display: flex;
                            flex-direction: column;
                            color: white;
                            text-align: center;
                            font-family: Quicksand;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: normal;
                            justify-content: center;
                            width: 60%;
                             margin: auto;
                        }

                        .input-container {
                            display: flex;
                            flex-direction: row;
                            border: 1px solid #000000;
                            border-radius: 6px;
                            outline: none;
                            width: 64%;
                            margin: auto;
                            margin-top: 20px;
                            margin-bottom: 20px !important;
                            background-color: #fff;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        
                            /* box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5); */
                        
                        }

                        .popup-container {
                            /* position: absolute; */
                            padding: 0.8rem;
                            z-index: 999999999999;
                            margin-left: 27%;
                            margin-right: 27%;
                            border-radius: 0.5rem;
                            width: 46%;
                            height: 600px;
                            opacity: calc(100);
                            background: linear-gradient(to right, #5A0223, #1E2744);
                        
                        }  

                        .popup-image {
                            width: 80%;
                            margin: auto;
                            display: flex;
                            height: 200px;
                            flex-shrink: 0;
                            margin-top: 30px;
                            margin-bottom: 30px;
                        }

                        .input-field {
                            width: 87%;
                            border: none;
                            text-align: center;
                            font-size: 20px;
                            background-color: #fff;
                        }

                        .icon-popup {
                            width: 13%;
                            background-color: #fff;
                            justify-content: center;
                            align-items: center;
                            padding-top: 2px;
                            padding-left: 8px;
                            font-size: 20px;
                            color: #A1A1A1;
                        }

                        .pop-img {
                            width: 40px;
                            height: 40px;
                            flex-shrink: 0;
                        }

                        .pop-imag {
                            display: flex;
                            gap: 28px;
                            margin-left: 40%;
                            margin-right: 40%;
                            margin-top: 28px;
                        }

                    }


    
    




    




