span.highlightColor{
    color: #0D6EFD;
}

.tcTitle{
    font-size: 26px;
    font-weight: 500;
    line-height: 37px;
    letter-spacing: 0em;
    text-align: center;
    width: 98%;
    margin: auto;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;

}

.tcSubTitle{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 2%;
}

.tcLevel1Title{
    font-size: 26px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: 0em;
    text-align: left;
    width: 98%;
    margin: auto;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
}

.tcPoints{
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justify;
    list-style-type: decimal;
    margin-top: 3%;
    margin-left: 2%;
}

.tcLevel1Text{
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 2%;
    margin-left: 2%;
}
@media screen and (min-device-width: 300px) and (max-device-width: 480px){
    .tcTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: center;
        width: 95%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
    
    }
    
    .tcSubTitle{
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 2%;
    }
    
    .tcLevel1Title{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        width: 98%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .tcPoints{
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        list-style-type: decimal;
        margin-top: 3%;
        margin-left: 2%;
    }
    
    .tcLevel1Text{
        font-size: 10px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 2%;
        margin-left: 2%;
    }
}

@media screen and (min-device-width: 481px) and (max-device-width: 680px){
    .tcTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 95%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
    
    }
    
    .tcSubTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 2%;
    }
    
    .tcLevel1Title{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        width: 98%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .tcPoints{
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        list-style-type: decimal;
        margin-top: 3%;
        margin-left: 2%;
    }
    
    .tcLevel1Text{
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 2%;
        margin-left: 2%;
    }
}

@media screen and (min-device-width: 681px) and (max-device-width: 980px){
    .tcTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 95%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
    
    }
    
    .tcSubTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 2%;
    }
    
    .tcLevel1Title{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
        width: 98%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .tcPoints{
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        list-style-type: decimal;
        margin-top: 3%;
        margin-left: 2%;
    }
    
    .tcLevel1Text{
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 2%;
        margin-left: 2%;
    }
}