

.AboutCompanyTitle{
    font-size: 22px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    width: 21.70%;
    text-align: center;
}


.AboutCompanySubtitle{
    font-size: 19px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
   
   
}
.about-description{
    font-family: Quicksand;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    text-align: justify;

}
@media screen and (min-device-width: 300px) and (max-device-width: 1080px) {
    .AboutCompanyTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        width: 60%;
    }
    
    
    .AboutCompanySubtitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;   
    }
}