.refback{
    background: #FFCB05;
   border-radius: 42px;
   

}

.heightrefund{
    height: 400px; 
    background: white;
}

.margin{
margin-bottom: 3rem;
margin-top: 3rem;
}

.sidespace{
 width: 80%;
 margin: auto;
}

.align{
    color: #5A0223;
    font-size: 26px;
    padding-top: 4px;
    padding-bottom: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500px;
}

.paraRefund{
    color: black;
    font-weight: 500;
    font-size: 16px;
    margin-top: 2rem ;
    margin-bottom: 2rem ;
    padding-left: 20px;
  
   
}
@media screen and (min-device-width: 300px) and (max-device-width: 500px){
    .align{
        color: #5A0223;
        font-size: 16px;
        font-weight: 500;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .paraRefund{
        color: black;
        font-weight: 500;
        font-size: 10px;
    }
}

@media screen and (min-device-width: 600px) and (max-device-width: 900px){
    .align{
        color: #5A0223;
        font-size: 14px;
        padding-top: 4px;
        padding-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .paraRefund{
        color: black;
        font-weight: 500;
        font-size: 12px;
    }
}