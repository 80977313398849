span.highlightColor{
    color: #FFCC00;
}

.gTitle{
    font-size: 26px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    width: 70%;
    margin: auto;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
}

.gText{
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: justify;
    margin-left: 2%;
    margin-right: 2%;
}

.gTableContainer{
    overflow: auto;
}

.gTable td,.gTable th{
    padding: 1%;
}

.gTable td{
    border-width: 0.4px;
    border-style: solid;
    border-color: #FFCC00;

    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
}

.gTable th{
    text-align: center;
}

.gTable .contentHeaderStyle{
    background-color: #FFCC00;
    color: #5A0223;
    font-size: 26px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    height: 46px;
}

.gTable .mainHeaderStyle{

    background: #FFCC00;
    color: #5A0223;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
}

.gTable .slWidth{
    width:143px;
}

.gTable .classificationWidth{
    width:209px;
}

.gTable .descriptionWidth{
    width:652px;
}

@media screen and (min-device-width: 300px) and (max-device-width: 480px){
    .gTitle{
        font-size: 14px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        width: 70%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .gText{
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
    
    }
    .gTable td{
        border-width: 0.4px;
        border-style: solid;
        border-color: #0D6EFD;
    
        font-size: 10px !important;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
    }
    
.gTable .contentHeaderStyle{
    background-color: #FFCC00;
    color: #5A0223;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    height: 46px;
}

.gTable .mainHeaderStyle{

    color: #5A0223;
    font-size: 10px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
}
}

@media screen and (min-device-width: 481px) and (max-device-width: 680px){
    .gTitle{
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        width: 70%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .gText{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
    
    }
    .gTable td{
        border-width: 0.4px;
        border-style: solid;
        border-color: #0D6EFD;
    
        font-size: 12px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
    }
    
.gTable .contentHeaderStyle{
    background-color: #FFCC00;
    color: #5A0223;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    height: 46px;
}

.gTable .mainHeaderStyle{

    color: #5A0223;
    font-size: 12px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
}
}

@media screen and (min-device-width: 681px) and (max-device-width: 980px){
    .gTitle{
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        width: 70%;
        margin: auto;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .gText{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
    
    }
    .gTable td{
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
    }
    
.gTable .contentHeaderStyle{
    background-color: #FFCC00;
    color: #5A0223;
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    height: 46px;
}

.gTable .mainHeaderStyle{
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
}
}


