@media screen and (max-width: 320px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 14px !important;
    line-height: 2rem !important;
  }
  .section-heading-band h3 {
    /* margin: 10px 0px 10px 0px !important; */
    padding: 0px 15px !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px 0px !important;
  }
  .crousel-card-banner {
    height: 150px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 25% 95% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
    padding-top: 0px !important;
  }
  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 4rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 26px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css end */
}
@media screen and (min-device-width: 321px) and (max-device-width: 340px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
  }
  .section-heading-band h3 {
    /* margin: 10px 0px 10px 0px !important; */
    padding: 0px 15px !important;
    line-height: 2rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px 0px !important;
  }
  .crousel-card-banner {
    height: 316px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 15px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner-content {
    padding: 12px 0px 0px !important;
  }
  .crousel-overly-movie-status {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 4rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 341px) and (max-device-width: 359px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
    width: 54% !important;
  }
  .section-heading-band h3 {
    line-height: 2rem !important;
    /* margin: 10px 0px 10px 0px !important; */
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
  }
  .crousel-card-banner {
    height: 220px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
    line-height: 14px !important;
    padding-top: 8px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 190px !important;
    left: 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 10px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 11.6px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}

@media screen and (min-device-width: 361px) and (max-device-width: 420px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
  }
  .section-heading-band h3 {
    line-height: 2rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
  }
  .crousel-card-banner {
    height: 220px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
    line-height: 14px !important;
    padding-top: 8px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 195px !important;
    left: 0px !important;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 10px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 11.6px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 425px) and (max-device-width: 540px) {
  /* carousel css res start */

  .section-heading-band h3 {
    line-height: 2rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
  }
  .crousel-card-banner {
    height: 254px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
    line-height: 14px !important;
    padding-top: 8px !important;
  }
  .custom-profile h1 {
    font-size: 16px !important;
  }
  .nav-menu.active {
    width: 50% !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 10px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 11.6px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 461px) and (max-device-width: 480px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
    line-height: 3.5rem !important;
  }
  .section-heading-band h3 {
    line-height: 2rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
  }
  .crousel-card-banner {
    height: 260px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
    line-height: 14px !important;
    padding-top: 8px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner-content {
    padding: 12px 0px 0px !important;
  }

  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3rem !important;
  }
  /*  */
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 481px) and (max-device-width: 540px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
  }
  .section-heading-band h3 {
    line-height: 2rem !important;
    width: 38% !important;
  }
  /* .slick-slider.slick-initialized {
    padding: 0px !important;
  } */

  
  .crousel-card-banner {
    height: 300px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }
  /* .crousel-overly-play-outer {
    height: 142px !important;
  } */
  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 541px) and (max-device-width: 680px) {
  /* carousel css res start */
  


  .section-heading h3 {
    font-size: 15px !important;
    line-height: 2.5rem !important;
    width: 32% !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
  }
  .crousel-card-banner {
    height: 250px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 14px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }

  .crousel-card-banner:hover .crousel-overly {
    width: 215px !important;
    left: 0px !important;
  }
  .slick-slide:nth-of-type(3n + 3):hover .crousel-overly {
    right: 1px !important;
    left: unset !important;
  }
  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 11px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 12px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 2px;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 681px) and (max-device-width: 768px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 16px !important;
    line-height: 3.9rem !important;
    width: 27% !important;
  }
  .section-heading-band h3 {
    line-height: 2.5rem !important;
  }
 

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 648px !important;
    margin: auto;
  }


  .crousel-card-banner {
    height: 150px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }
  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
    padding-top: 0px !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 13px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 0px !important;
    letter-spacing: 0px !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 769px) and (max-device-width: 890px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 18px !important;
    line-height: 3rem !important;
    width: 27% !important;
  }
  .crousel-card-banner {
    height: 300px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 210px !important;
    left: 0px !important;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
  }
  .crousel-overly-movie-details {
    padding: 8px 0px 0px 0px;
  }
  .crousel-overly-movie-short-description {
    font-size: 13px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 2px;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 891px) and (max-device-width: 990px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 20px !important;
    line-height: 3rem !important;
    width: 26% !important;
  }
  .crousel-card-banner {
    height: 300px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    font-size: 12px !important;
  }
  .crousel-overly-movie-details {
    padding: 8px 0px 0px 0px;
  }
  .crousel-overly-movie-short-description {
    font-size: 13px !important;
    line-height: 26px !important;
    font-weight: 400 !important;
    padding-top: 2px;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}


@media screen and (min-device-width: 991px) and (max-device-width: 1023px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 22px !important;
    line-height: 3rem !important;
    width: 25% !important;
  }}




@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  /* carousel css res start */

  .crousel-card-banner {
    height: 300px;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 654px !important;
    margin: auto;
  }

  /*  */
  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-status {
    padding-top: 0px !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 13px !important;
    font-weight: 400 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
/* responsiv on mac */
@media screen and (min-device-width: 1024px) and (max-device-width: 1080px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 23px !important;
    line-height: 3rem !important;
    width: 26% !important;
  }
  .section-heading-band h3 {
    line-height: 3rem !important;
  }
  .crousel-card-banner {
    height: 176px !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 660px !important;
    margin: auto;
  }

  
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-content-outer {
    padding: 10px 6px !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1081px) and (max-device-width: 1180px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 24px !important;
    line-height: 4rem !important;
    width: 26% !important;
  }
  .section-heading-band h3 {
    line-height: 3rem !important;
  }
  .crousel-card-banner {
    height: 190px !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
      width: 810px !important;
      margin: auto;
    }
  
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1181px) and (max-device-width: 1220px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 24px !important;
    line-height: 3rem !important;
    width: 24% !important;
  }
  
  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 810px !important;
    margin: auto;
  }

  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1221px) and (max-device-width: 1260px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 24px !important;
    line-height: 4rem !important;
    width: 22% !important;
  }
  .section-heading-band h3 {
    line-height: 3rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 820px !important;
    margin: auto;
  }
  .crousel-card-banner {
    height: 175px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1261px) and (max-device-width: 1320px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 24px !important;
    line-height: 4rem !important;
    width: 22% !important;
  }
  .section-heading-band h3 {
    line-height: 3rem !important;
  }
  .crousel-card-banner {
    height: 160px !important;
  }
  .crousel-wrapper .crousel-card {
    width: 95% !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 820px !important;
    margin: auto;
  }

  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
    padding-top: 4px;
    font-weight: 600;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 12px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  .crousel-overly-content-outer {
    padding: 6px 6px !important;
  }
  .crousel-overly-movie-status {
    line-height: 22px !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1321px) and (max-device-width: 1380px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 26px !important;
    line-height: 3.0 rem !important;
    width: 23% !important;
  }
  .crousel-card-banner {
    height: 175px !important;
  }
  .section-heading-band h3 {
    line-height: 3.2rem !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 820px !important;
    margin: auto;
  }

  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 17px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .slick-slide.slick-active.slick-current:hover .crousel-overly {
    left: 0;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }
  .crousel-overly-movie-status {
    padding-top: 0px;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 22px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  .crousel-overly-content-outer {
    padding: 6px 6px !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1381px) and (max-device-width: 1440px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 26px !important;
    line-height: 4.5rem !important;
    width: 22% !important;
  }
  .crousel-card-banner {
    height: 180px !important;
  }
  .section-heading-band h3 {
    line-height: 3.4rem !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 17px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }
  .crousel-overly-movie-status {
    line-height: 20px !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 820px !important;
    margin: auto;
  } 


  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
    left: 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1441px) and (max-device-width: 1580px) {
  .crousel-card-banner {
    height: 160px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 18px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 16px !important;
  }
  .slick-slide.slick-active.slick-current:hover .crousel-overly {
    left: 0;
  }
  .crousel-overly {
    left: 0 !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 830px !important;
    margin: auto;
  }

  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }
  .crousel-overly-movie-status {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  .crousel-overly-content-outer {
    padding: 6px 6px !important;
  }
  /* carousel css res end */
}
@media screen and (min-device-width: 1581px) and (max-device-width: 1780px) {
  /* carousel css res start */
  .crousel-card-banner {
    height: 190px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 80% 70% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 18px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 16px !important;
  }
  .slick-slide.slick-active.slick-current:hover .crousel-overly {
    left: 0;
  }
  .crousel-overly {
    left: 0 !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 18px !important;
    font-weight: 600 !important;
  }

  .slick-slider.slick-initialized {
    padding: 0px !important;
    width: 840px !important;
    margin: auto;
  }


  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }
  .crousel-overly-movie-status {
    font-size: 14px !important;
  }
  .crousel-card-banner:hover .crousel-overly {
    width: 100% !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }
  .crousel-overly-movie-short-description {
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 500 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 300 !important;
  }
  .crousel-overly-content-outer {
    padding: 6px 6px !important;
  }
  /* carousel css res end */
}


@media screen and (min-device-width: 1781px) and (max-device-width: 1909px) {
  /* carousel css res start */
  .slick-slider.slick-initialized {
    padding: 0px !important;
    margin: auto;
    width: 842px !important;
  }
  .crousel-card-banner {
    height: 175px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  /* carousel css res end */
}



@media screen and (min-device-width: 1910px) and (max-device-width: 2100px) {
  /* carousel css res start */
  .section-heading h3 {
    font-size: 24px !important;
    line-height: 4rem !important;
  }
  .section-heading-band h3 {
    line-height: 3rem !important;
  }
  .slick-slider.slick-initialized {
    padding: 0px !important;
   width: 852px !important; 
   margin: auto;
  }
  .crousel-card-banner {
    height: 175px !important;
  }
  .crousel-card
    .crousel-card-outer-sec
    .crousel-card-banner
    .crousel-card-image {
    object-position: 100% 27% !important;
  }
  .crousel-card-banner-content h5 {
    font-size: 16px !important;
    line-height: 20px !important;
    padding-top: 0px !important;
  }
  .crousel-card-banner-content .card-banner-language {
    font-size: 14px !important;
  }
  .crousel-overly-inner .crousel-overly-movie-title {
    font-size: 16px !important;
    font-weight: 800 !important;
  }
  .crousel-card-banner-content {
    padding: 10px 0px 0px !important;
  }

  .crousel-overly-play-outer .MuiSvgIcon-root {
    font-size: 3.5rem !important;
  }

  .crousel-overly-movie-short-description {
    font-size: 15px !important;
    line-height: 16px !important;
    font-weight: 600 !important;
  }
  .crousel-overly-movie-long-description {
    font-size: 13px !important;
    word-spacing: 0px !important;
    font-weight: 500 !important;
  }
  /* carousel css res end */
}