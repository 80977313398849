.main-content {
  /* padding-top: 94px; */
  padding-bottom: 4rem;
}
.submit-movie-note {
  color: #FFFFFF;
  font-weight: 600;
  margin-left: 5px;
  font-size: 14px;
}
.submit-movie-note span {
  font-weight: 400 !important;
  margin-left: 10px;
}

.submit-movie-wrapper {
  margin-top: 4rem;
}

.main-cont {
  margin-bottom: 50px;   
  padding-bottom: 50px;
  /* background: linear-gradient(111.21deg, #5C0223 -0.12%, #1E2744 109.78%); */
  
}

/*.star{
  color: red;
  font-size: 20px;
  font-weight: bold;
  
} */

.placehold{
  width:30% !important;
}


.outer{
  background: white;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  
}

.bord{
  border-radius: 10px !important;
  padding: 10px, 60px, 10px, 60px;
}



.login {
  padding-top: 12px;
  padding-bottom: 12px;
} 