.ref-codes {
    /* display: inline-block; or inline */
   }
  
  .icon1 {
    display: inline-block; /* or inline */
  }
  .profilebtn{
    font-size: 10px;
    font-weight: 700;
    padding: 5px;
    background: none;
    color: #5A0223;
    border: none;
    margin-top: 5px;
  }

  .profilebtn:hover{
    background: #f5d13e;
  }


  @media screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .copiedtext{
   font-size: 8px;
  }
}

@media screen and (min-device-width: 480px) and (max-device-width: 700px) {
  .copiedtext{
   font-size: 10px;
  }
}

@media screen and (min-device-width: 700px) and (max-device-width: 800px) {
  .copiedtext{
   font-size: 12px;
  }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1000px) {
  .copiedtext{
   font-size: 13px;
  }
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1300px) {
  .copiedtext{
   font-size: 15px;
  }
}

@media screen and (min-device-width: 1300px) and (max-device-width: 1600px) {
  .copiedtext{
   font-size: 16px;
  }
}