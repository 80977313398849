.AboutFounderTitle{
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 30%;
    text-align: center;
}
@media screen and (min-device-width: 300px) and (max-device-width: 420px){
    .AboutFounderTitle{
        font-size: 16px;
        width: 50%;
    } 
}
@media screen and (min-device-width: 421px) and (max-device-width: 560px){
    .AboutFounderTitle{
        font-size: 16px;
        width: 35%;
    } 
}
@media screen and (min-device-width: 561px) and (max-device-width: 1080px){
    .AboutFounderTitle{
        font-size: 18px;
        width: 30%;
    } 
}