.footer-section-main {
  padding: 32px 0px;
  background: linear-gradient(to right, #5A0223, #1E2744);
  text-align: center;
  color: #fff;
  margin-top: auto;
}
.footer-icons svg.MuiSvgIcon-root {
  font-size: 34px;
  margin: 0px 10px;
  cursor: pointer;
  color: #ffcc00;
}
.footer-text p {
  font-size: 16px;
  color: #ffff;
  font-weight: 400;
}

.Xicon{
  width: 20px;
  height: 20px;
  margin: 0px 5px;

}

.startup-text{
  margin-top: 2px;
  font-weight: 600 !important;
}

.beyond-text{
  margin-top: 16px;
  margin-bottom: 16px;
  
}

.footer-section-main hr.line-footer {
  border: none !important;
}

@media screen and (max-width: 320px) {
  .footer-section-main {
   margin-top: 12px; 
  }
  .footer-section-main hr.line-footer {
    margin: 20px 0px !important;
  }
}

@media screen and (max-width: 480px) {
  .footer-section-main hr.line-footer {
    margin: 15px 0px !important;
  }
}
@media screen and (min-device-width: 541px) and (max-device-width: 640px) {
  .footer-icons svg.MuiSvgIcon-root {
    font-size: 28px !important;
    margin: 0 4px !important;
  }
  .footer-section-main hr.line-footer {
    margin: 15px 0 !important;
  }
  .footer-section-main {
    padding: 20px 0px;
  }
}
@media screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .footer-icons svg.MuiSvgIcon-root {
    font-size: 30px !important;
    margin: 0px 4px !important;
  }

  .Xicon{
    width: 20px;
    height: 20px;
    margin: 0px 5px;

  }
}
@media screen and (min-device-width: 1025px) and (max-device-width: 1480px) {
  .footer-section-main hr.line-footer {
    border: none !important;
  }
  .footer-text p {
    font-size: 16px !important;
    margin-bottom: 0.5rem !important;
  }
  .footer-icons svg.MuiSvgIcon-root {
    font-size: 32px !important;
    margin: 0 8px !important;
  }
  .footer-text {
    padding: 0px 10px !important;
  }
}

.footer-link{
  color: white;
  text-decoration: none;
}

.footer-link:hover{
  color: #ffcc00;
  cursor: pointer;
}

.mr-1{
  margin-right: 1%;
}


@media screen and  (min-device-width:620px) and (max-device-width:1440px ){
  .responsive-image {
    width: 490px;
    height: 158px;
    margin-bottom: 8px;
    border-radius: 12px;
   
  }
}

/* Default styles for the image */

@media screen and (min-device-width: 211px) and (max-device-width: 309px) {
  .responsive-image {
    padding-bottom: 12px;
   width: 180px;
  height: 80px; 
   border-radius: 12px;
  }

  .footer-titles{
    font-size: 8px;
    display: flex;
    justify-content: space-between;
   
    font-weight: 200;
  }

  .footer-link{
    color: white;
    padding-right: 10px;
    text-decoration: none;
  }
  
  .footer-link:hover{
    color: #ffcc00;
    cursor: pointer;
  }
  

.footer-section-main {
      padding: 8px 0px;
      background: linear-gradient(to right, #5A0223, #1E2744);
      text-align: center;
      color: #fff;
      margin-top: auto;
    }
    
   /* .startup-text{
      margin-top: 2px;
      font-size: 8px;
    }
    
    .beyond-text{
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 8px;
    } */
    
 
  .footer-section-main {
    padding: 2px 0px;
    background: linear-gradient(to right, #5A0223, #1E2744);
    text-align: center;
    color: #fff;
    margin-top: auto;
  }
  .footer-icons svg.MuiSvgIcon-root {
    margin: 0px 10px;
    cursor: pointer;
    color: #ffcc00;
  }
  .footer-text p {
    font-size: 12px;
    color: #ffff;
    font-weight: 200;
  }

  .Xicon{
    width: 16px;
    height: 14px;
    margin: 0px 5px;
    padding-right: 4px;

  }

  .FacebookIcon{
    width: 16px;
    height: 14px;
    margin: 0px 5px;
    padding-right: 4px;
  }

  .YouTubeIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px;
}

  .LinkedInIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px;
}

.InstagramIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px; 
}

  .mr-1{
    margin-right: 0%;
  }
  
}

/* Media query for screens smaller than 480px */

@media screen and (min-device-width: 310px) and (max-device-width: 620px) {
  .responsive-image {
   width: 138px;
   height: 51px; 
   border-radius: 8px !important;
   margin-bottom: 6px;
  }

  .footer-titles{
    font-size: 8px;
    display: flex;
    justify-content: space-between;
    width: 245px;
    margin: auto;
    font-weight: 200;
  }

.footer-section-main {
      padding: 8px 0px;
      background: linear-gradient(to right, #5A0223, #1E2744);
      text-align: center;
      color: #fff;
      margin-top: auto;
    }
    
    /* .startup-text{
      margin-top: 2px;
      font-size: px;
    
    }
    
    .beyond-text{
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 8px;
    } */
    

    .footer-link{
      color: white;
      text-decoration: none;
      padding-right: 2px;
    }
    
    .footer-link:hover{
      color: #ffcc00;
      cursor: pointer;
    }
    
 
  .footer-section-main {
    padding: 2px 0px;
    background: linear-gradient(to right, #5A0223, #1E2744);
    text-align: center;
    color: #fff;
    margin-top: auto;
  }
  .footer-icons svg.MuiSvgIcon-root {
    margin: 0px 10px;
    cursor: pointer;
    color: #ffcc00;
  }
  .footer-text p {
    font-size: 8px;
    /* width: 239px; */
    margin: auto;
    color: #ffff;
    font-weight: 200;
  }

  .Xicon{
    width: 16px;
    height: 14px;
    margin: 0px 5px;
    padding-right: 4px;

  }

  .FacebookIcon{
    width: 16px;
    height: 14px;
    margin: 0px 5px;
    padding-right: 4px;
  }

  .YouTubeIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px;
}

  .LinkedInIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px;
}

.InstagramIcon{
  width: 16px;
  height: 14px;
  margin: 0px 5px;
  padding-right: 4px; 
}

  .mr-1{
    margin-right: 0%;
  }
  
}

@media screen and (min-device-width: 611px) and (max-device-width: 820px) {
  .responsive-image {
    width: 320px;
    height: 120px; 
    border-radius: 12px;
  }

  .footer-titles{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
    width: 80%;
    font-weight: 200;
  }

  .footer-link{
    color: white;
    text-decoration: none;
  }
  
  .footer-link:hover{
    color: #ffcc00;
    cursor: pointer;
  }
  

.footer-section-main {
      padding: 8px 0px;
      background: linear-gradient(to right, #5A0223, #1E2744);
      text-align: center;
      color: #fff;
      margin-top: auto;
    }
    
    /* .startup-text{
      margin-top: 2px;
      font-size: px;
    
    }
    
    .beyond-text{
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 8px;
    } */
    
 
  .footer-section-main {
    padding: 2px 0px;
    background: linear-gradient(to right, #5A0223, #1E2744);
    text-align: center;
    color: #fff;
    margin-top: auto;
  }
  .footer-icons svg.MuiSvgIcon-root {
    margin: 0px 10px;
    cursor: pointer;
    color: #ffcc00;
  }
  .footer-text p {
    font-size: 16px;
    color: #ffff;
    font-weight: 200;
  }

  .Xicon{
    width: 22px;
    height: 20px;
    margin: 0px 5px;
    padding-right: 8px;

  }

  .FacebookIcon{
    width: 22px;
    height: 20px;
    margin: 0px 5px;
    padding-right: 8px;
  }

  .YouTubeIcon{
  width: 22px;
  height: 20px;
  margin: 0px 5px;
  padding-right: 8px;
}

  .LinkedInIcon{
  width: 22px;
  height: 20px;
  margin: 0px 5px;
  padding-right: 8px;
}

.InstagramIcon{
  width: 22px;
  height: 20px;
  margin: 0px 5px;
  padding-right: 8px; 
}

  .mr-1{
    margin-right: 0%;
  }
  
}

@media screen and (min-device-width: 820px) {
  .responsive-image {
    margin-bottom: 12px;
  }
  .refernow-body {
    align-items: center;
    justify-content: center;
  }
  .foot-title-div .footer-link {
    margin-right: 3%;
    font-size: 12px;
  }
}

