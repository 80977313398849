.selectCountryIcon {
    height: 40px;
    width: 40px;
}

.selectDropDownIcon {
    height: 20px;
    width: 20px;
}

.dropDownCountryOutline {
    border: 1px solid white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    height: 50px;
    background: white;
}

.selectCountryIconSeparator {
    border-right: 0.7px solid #68686880;
    height: 31px;
    margin-left: 3px;
    margin-right: 3px;
}

.selectCountryIconBtn {
    height: 44px;
    width: 82px;
}

.mobileInputBox {
    width: 440px;
    height: 50px;
    padding: 6px 218px 6px 5px;
    border-radius: 30px;
    border: 1px solid white;
    background: white;
    display: flex;
    align-items: center;
    margin-left: 1%;
    /* margin-bottom: 20px; */
}

.inputBoxError{
    border: 1px solid #FD180C;
}

.countryCodePlaceholder {
    margin-right: 20px;
    background-color: #EAEAEA;
    padding: 4%;
    text-align: center;
    border-radius: 50%;
}
.mobileInput{
    width: 360px;
}

.numberInput {
    border: 0;
    outline: 0;
}

.numberInput:focus {
    outline: none !important;
}

/* Chrome, Safari, Edge, Opera */
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.numberInput[type=number] {
    -moz-appearance: textfield;
}


.otpBtn {
    width: 166px  ;
    height: 50px !important;
    border-radius: 30px !important;
    background-color:  #FFCC00  !important; 
    /* background-color: linear-gradient(90deg, #FFCC00 0%, #FFB800 100%) !important; */
    color: #5A0223 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-left: 20px !important;
    font-family: Quicksand !important;
    line-height: 26px;
}

/* .otpBtn {
    font-family: Quicksand, serif !important;
    width: 166px !important;
    height: 50px !important;
    border-radius: 30px !important;
    background-color: #0D6EFD !important;
    color: white !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    margin-left: 20px !important;
} */


.otpBtn:hover {
    background-color:  #FFCC00  !important; 
    /* background-color: rgba(13, 110, 253, 0.71) !important; */
}

.errorText {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FD180C;
    margin-left: 5%;
    /*margin-top: -20px;*/
}

.otpInputSection {
    font-family: Quicksand;
    color: #FFB800;
}

.OTPEnterText {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
}

.OTPSentText {
    font-size: 13px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-align: center;
}



.otpInput {
    border-bottom: 2px solid white;
    width: 50px;
    height: 30px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    font-family: Quicksand;
    color: #FFCC00;
    background: none;
  
}

.otpInput:focus {
    border-bottom: 2px solid #FFB800;
}

.timeRemainingText {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
    color: white
}

.verifyOTPBtn {
    width: 270px;
    height: 36px;
    border-radius: 20px !important;
    background-color: #FFB800 !important;
    color: #5A0223 !important;
}

.verifyOTPBtn:hover {
    background-color:  #FFB800  !important; 
    /* background-color: rgba(13, 110, 253, 0.6) !important; */
}

.otpInputContainer {
    margin-left: 10px;
    margin-bottom: 20px;
}

.otpInputError {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: #FD180C;
    margin-top: -10px;
}

.didntGetOTP {
    font-size: 14px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-align: center;
    color: #FFFFFF;
}

.resendOTP {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.1em;
    color: #FFCC00;
    cursor: pointer;
}

.resendOTP:hover {
    color: rgba(13, 110, 253, 0.74);
}

.verifyTickContainer {
    width: 140px;
    height: 140px;
    padding: 20px;
    border-radius: 50%;
    /* background-color: #0D6EFD;  */
    background: linear-gradient(to right, #D3A72F, #994145);
    display: flex;
    align-items: center;
    justify-content: center;
}

.verifyTickIcon {
    width: 100px;
    height: 100px;
}

.verifiedText {
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    color: #FFFFFF;

}


.devider{
    position: relative;
}

.orbutton{
    position: absolute;
    bottom: 10px !important;
    right: 50%;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    
}

.rightArrowIcon {
    width: 18.3px;
    height: 9.9px;
    margin-left: 3px;
    color: #5A0223;
}

.getStartedBtn {
    width: 150px;
    height: 36px;
    background-color: #FFCC00 !important;
    color: #5A0223 !important;
    border-radius: 25px !important;
}

.getStartedBtn:hover{
    /* background-color: red !important; */
}


.captchaContainer{
    display: none !important;
}
.captchaResendContainer{
}

.disabledButton{
    background-color: lightgray !important;
}
.login-with-email-div{
    text-align: center;
    justify-content: center;
    align-items: center;
}
.sign-up-with-email{
    border: none;
}
.login-with-email-div button{
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border: none;
}
hr{
    margin: 3rem 0;
}
.linklogin{
    
    position: sticky;
    /* bottom:240px; */
}

@media (min-width: 320px) and (max-width: 380px)  {
    .linklogin{
        bottom: 100px !important;
    }

    .devider{
        position: relative;
        margin-bottom:20px;
        margin-top:20px;
    }
    
    .orbutton{
        /* position: sticky;
        top: 20px !important;
        right: 50%; */
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        /* margin-top: 36px; */
        
    }
    
    .mobileInputBox {
        width: 230px;
        height: 36px;
        /* padding: 6px 18px 6px 5px; */
        border-radius: 30px;
        /* position: absolute; */
        /* margin-left: 16px; */
    }
    .selectCountryIcon {
        height: 18px;
        width: 18px;
    }
    .dropDownCountryOutline {
        height: 36px;
        padding: 0px 20px 0px 20px;
        margin-right: 8px;
        margin-left: 278px;
    }
    .selectDropDownIcon {
        height: 10px;
        width: 18px;
        margin-right: 10px;
    }

    .selectCountryIconSeparator {
        height: 13px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .selectCountryIconBtn {
        height: 14px;
        width: 22px;
    }
    
    .countryCodePlaceholder {
        margin-right: 10px;
        padding: 4%;
    }
    .mobileInput{
        /* width: 117px !important; */
        height: 26px;
    }
    .otpBtn {
        min-width: 260px !important;
        height: 36px !important;
        border-radius: 30px !important;
        margin-top: 10px;
        right:290px !important;
        top: 60px;
    }
}
@media (min-width: 381px) and (max-width: 480px)  {
    .linklogin{
    
        position: sticky;
        bottom: 150 !important; 
        padding-top: 400px;
    }

    .devider{
        position: relative;
        margin-bottom:20px;
    }

    .orbutton{
        /* position: sticky;
        top: 30px !important;
        right: 50%; */
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        /* margin-top: 36px; */
        
    }
    .mobileInputBox {
        width: 232px;
        height: 36px;
        /* padding: 6px 18px 6px 5px; */
        border-radius: 30px;
        /* position: absolute; */
    }

    
    .selectCountryIcon {
        height: 20px;
        width: 20px;
    }
    .dropDownCountryOutline {
        border-radius: 50px;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0px 20px 0px 20px;
        margin-right: 8px;
        margin-left: 280px;
        
    }
    .selectDropDownIcon {
        height: 10px;
        width: 18px;
    }
    .selectCountryIconSeparator {
        height: 15px;
        margin-left: 3px;
        margin-right: 3px;
    }
    
    .selectCountryIconBtn {
        height: 16px;
        width: 24px;
    }
    
    .countryCodePlaceholder {
        margin-right: 13px;
        padding: 4%;
    }
    .mobileInput{
        height: 26px;
    }
    .otpBtn {
        min-width: 260px !important;
        height: 36px !important;
        border-radius: 30px !important;
        margin-top: 10px;
        right: 296px !important;
        top: 60px;
    }
}

@media (min-width: 481px) and (max-width: 580px)  {
    .orbutton{
        /* position: sticky;
        top: 10px !important;
        right: 50%; */
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        justify-content: center;
        /* margin-top: 36px; */
        
    }
    

    .devider{
        position: relative;
        margin-bottom:20px;
    }

    .mobileInputBox {
        width: 278px;
        height: 36px;
        /* padding: 6px 18px 6px 5px; */
        border-radius: 30px;
        /* position: absolute; */
    }
    .selectCountryIcon {
        height: 25px;
        width: 25px;
    }
    .dropDownCountryOutline {
        border-radius: 50px;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 0px 20px 0px 20px;
        margin-right: 8px;
        margin-left: 330px;
    }

    .selectDropDownIcon {
        height: 10px;
        width: 18px;
    }
    .selectCountryIconSeparator {
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
    }
    
    .selectCountryIconBtn {
        height: 20px;
        width: 26px;
    }
    
    .countryCodePlaceholder {
        margin-right: 13px;
        padding: 4%;
    }
    .mobileInput{
        width: 300px !important;
        height: 30px;
    }
    .otpBtn {
        min-width: 290px !important;
        height: 36px !important;
        border-radius: 30px !important;
        margin-top: 10px;
        right: 328px !important;
        top: 60px;
    }

    .input-text{
    margin-top: 80px !important;
    }

    /* .otpBtn {
        min-width: 260px !important;
        height: 36px !important;
        border-radius: 30px !important;
        margin-top: 10px;
        right:300px !important;
        top: 60px;
    } */
}


@media (min-width: 581px) and (max-width: 780px)  {
    .linklogin{
        bottom:642px !important;
        position: sticky;
        
    }

  

    .mobileInputBox {
        width: 230px;
        height: 36px;
        /* padding: 6px 18px 6px 5px; */
        border-radius: 30px;
    }
    .selectCountryIcon {
        height: 25px;
        width: 25px;
        margin-left: 2px;
    }
    .dropDownCountryOutline {
        border-radius: 40px;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 5px 20px 5px 20px;
    }
    .selectDropDownIcon {
        height: 14px;
        width: 18px;
        margin-right: 2px;
    }
    .selectCountryIconSeparator {
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
    }
    
    .selectCountryIconBtn {
        height: 20px;
        width: 26px;
    }
    
    .countryCodePlaceholder {
        margin-right: 13px;
        padding: 4%;
    }
    .mobileInput{
        width: 280px !important;
        height: 30px;
    }
    .otpBtn {
        width: 120px  ;
        height: 36px !important ;
        font-size: 12px !important;
        padding: 0px !important;
        
    }
}


@media (min-width: 781px) and (max-width: 1025px)  {
    .mobileInputBox {
        width: 290px;
        height: 36px;
        /* padding: 6px 18px 6px 5px; */
        border-radius: 30px;
    }
    .selectCountryIcon {
        height: 25px;
        width: 25px;
        margin-left: 2px;
    }
    .dropDownCountryOutline {
        border-radius: 40px;
        display: flex;
        align-items: center;
        height: 36px;
        padding: 5px 20px 5px 20px;
        margin-right: 4px;
    }
    .selectDropDownIcon {
        height: 14px;
        width: 18px;
        margin-right: 2px;
    }
    .selectCountryIconSeparator {
        height: 20px;
        margin-left: 4px;
        margin-right: 4px;
    }
    
    .selectCountryIconBtn {
        height: 20px;
        width: 26px;
    }
    
    .countryCodePlaceholder {
        margin-right: 13px;
        padding: 4%;
    }
    .mobileInput{
        width: 280px !important;
        height: 30px;
    }
    .otpBtn {
        width: 120px  ;
        height: 36px !important ;
        font-size: 12px !important;
        padding: 0px !important;
        
    }
}

@media (min-width: 1026px) and (max-width: 1960px)  {
.OTPModalStyl {
    margin-top: 360px !important;
    /* margin-left: 320px; */
    padding: 10px 30px 20px 80px !important;
    /* position: sticky; */
    background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
    width: 924px;
    margin: auto !important;
    height: 224px;
    border-radius: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
} }

@media (min-width: 1026px) and (max-width: 1960px)  {
.otpsucess {
    margin-top: 360px !important;
    /* margin-left: 320px; */
    padding: 10px 30px 20px 80px !important;
    /* position: sticky; */
    background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
    width: 524px;
    margin: auto !important;
    height: 324px;
    border-radius: 30px;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
}


@media (min-width: 320px) and (max-width: 560px)  {
.verifyTickContainer {
    width: 100px;
    margin: auto;
    height: 100px;
    padding: 20px;
    border-radius: 50%;
    /* background-color: #0D6EFD;  */
    background: linear-gradient(to right, #D3A72F, #994145);
    display: flex;
    align-items: center;
    justify-content: center;
}

.verifyTickIcon {
    width: 80px;
    height: 80px;
}

.verifiedText {
    font-family: Quicksand, sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    color: #FFFFFF;
    margin-bottom: 8px;

}

.getStartedBtn {
    width: 270px;
    margin: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    background-color: #FFCC00 !important;
    color: #5A0223 !important;
    border-radius: 25px !important;
    
}

.getStartedBtn:hover{
    /* background-color: red !important; */
}


.otpInput {
    border-bottom: 2px solid white;
    width: 28px;
    height: 30px;
    margin-right: 20px;
    text-align: center;
    font-size: 16px;
    font-family: Quicksand;
    color: #FFCC00;
    background: none;
  
}

.timeRemainingText {
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    margin-top: 5px;
    color: #FFCC00;
}

.errorText {
    font-family: Quicksand;
    font-size: 14px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    color: #FD180C;
    margin-left: 5%;
    /*margin-top: -20px;*/
}

.otpInputSection {
    font-family: Quicksand;
    color: #FFB800;
}

.OTPEnterText {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
   
}

.OTPSentText {
    font-size: 11px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.1em;
    text-align:start;
    
}


}

@media (min-width: 561px) and (max-width: 860px)  {
    .verifyTickContainer {
        width: 100px;
        margin: auto;
        height: 100px;
        padding: 20px;
        border-radius: 50%;
        /* background-color: #0D6EFD;  */
        background: linear-gradient(to right, #D3A72F, #994145);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .verifyTickIcon {
        width: 80px;
        height: 80px;
    }
    
    .verifiedText {
        font-family: Quicksand, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 20px;
        color: #FFFFFF;
        margin-bottom: 8px;
    
    }
    
    .getStartedBtn {
        width: 270px;
        margin: auto !important;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 36px;
        background-color: #FFCC00 !important;
        color: #5A0223 !important;
        border-radius: 25px !important;
        
    }
    
    .getStartedBtn:hover{
        /* background-color: red !important; */
    }

    .otpInput {
        border-bottom: 2px solid white;
        width: 30px;
        height: 30px;
        margin-right: 20px;
        text-align: center;
        font-size: 16px;
        font-family: Quicksand;
        color: #FFCC00;
        background: none;
      
    }
    
    .timeRemainingText {
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        text-align: center;
        margin-top: 5px;
        color: #FFCC00;
    }
    
    .errorText {
        font-family: Quicksand;
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        color: #FD180C;
        margin-left: 5%;
        /*margin-top: -20px;*/
    }
    
    .otpInputSection {
        font-family: Quicksand;
        color: #FFB800;
    }
    
    .OTPEnterText {
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
       
    }
    
    .OTPSentText {
        font-size: 11px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0.1em;
        text-align:start;
        
    }
    }


    @media (min-width: 861px) and (max-width: 1025px)  {
        .verifyTickContainer {
            width: 100px;
            margin: auto;
            height: 100px;
            padding: 20px;
            border-radius: 50%;
            /* background-color: #0D6EFD;  */
            background: linear-gradient(to right, #D3A72F, #994145);
            display: flex;
            align-items: center;
            justify-content: center;
        }
        
        .verifyTickIcon {
            width: 80px;
            height: 80px;
        }
        
        .verifiedText {
            font-family: Quicksand, sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 20px;
            color: #FFFFFF;
            margin-bottom: 8px;
        
        }
        
        .getStartedBtn {
            width: 210px;
            margin: auto !important;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            background-color: #FFCC00 !important;
            color: #5A0223 !important;
            border-radius: 25px !important;
            
        }
        
        .getStartedBtn:hover{
            /* background-color: red !important; */
        }

        .otpInput {
            border-bottom: 2px solid white;
            width: 36px;
            height: 30px;
            margin-right: 20px;
            text-align: center;
            font-size: 16px;
            font-family: Quicksand;
            color: #FFCC00;
            background: none;
          
        }
        
        .timeRemainingText {
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            text-align: center;
            margin-top: 5px;
            color: #FFCC00;
        }
        
        .errorText {
            font-family: Quicksand;
            font-size: 14px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0em;
            color: #FD180C;
            margin-left: 5%;
            /*margin-top: -20px;*/
        }
        
        .otpInputSection {
            font-family: Quicksand;
            color: #FFB800;
        }
        
        .OTPEnterText {
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 0em;
           
        }
        
        .OTPSentText {
            font-size: 13px;
            font-weight: 400;
            line-height: 26px;
            letter-spacing: 0.1em;
            text-align:start;
            
        }
        }


        @media (min-width: 1026px) and (max-width: 1500px)  {
            .verifyTickContainer {
                width: 100px;
                margin: auto;
                height: 100px;
                padding: 20px;
                border-radius: 50%;
                /* background-color: #0D6EFD;  */
                background: linear-gradient(to right, #D3A72F, #994145);
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            .verifyTickIcon {
                width: 80px;
                height: 80px;
            }
            
            .verifiedText {
                font-family: Quicksand, sans-serif;
                font-size: 20px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
                text-align: center;
                margin-top: 20px;
                color: #FFFFFF;
                margin-bottom: 8px;
            
            }
            
            .getStartedBtn {
                width: 170px;
                margin: auto !important;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 36px;
                background-color: #FFCC00 !important;
                color: #5A0223 !important;
                border-radius: 25px !important;
                
            }
            
            .getStartedBtn:hover{
                /* background-color: red !important; */
            }
            .otpInput {
                border-bottom: 2px solid white;
                width: 40px;
                height: 30px;
                margin-right: 20px;
                text-align: center;
                font-size: 16px;
                font-family: Quicksand;
                color: #FFCC00;
                background: none;
              
            }
            
            .timeRemainingText {
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                text-align: center;
                margin-top: 5px;
                color: #FFCC00;
            }
            
            .errorText {
                font-family: Quicksand;
                font-size: 14px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0em;
                color: #FD180C;
                margin-left: 5%;
                /*margin-top: -20px;*/
            }
            
            .otpInputSection {
                font-family: Quicksand;
                color: #FFB800;
            }
            
            .OTPEnterText {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                letter-spacing: 0em;
               
            }
            
            .OTPSentText {
                font-size: 13px;
                font-weight: 400;
                line-height: 26px;
                letter-spacing: 0.1em;
                text-align:start;
                
            }
            }

            @media (min-width: 1500px) and (max-width: 2260px)  {
                .verifyTickContainer {
                    width: 100px;
                    margin: auto;
                    height: 100px;
                    padding: 20px;
                    border-radius: 50%;
                    /* background-color: #0D6EFD;  */
                    background: linear-gradient(to right, #D3A72F, #994145);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                
                .verifyTickIcon {
                    width: 80px;
                    height: 80px;
                }
                
                .verifiedText {
                    font-family: Quicksand, sans-serif;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 26px;
                    letter-spacing: 0em;
                    text-align: center;
                    margin-top: 20px;
                    color: #FFFFFF;
                    margin-bottom: 8px;
                
                }
                
                .getStartedBtn {
                    width: 160px;
                    margin: auto !important;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    background-color: #FFCC00 !important;
                    color: #5A0223 !important;
                    border-radius: 25px !important;
                    
                }
                
                .getStartedBtn:hover{
                    /* background-color: red !important; */
                }

                .otpInput {
                    border-bottom: 2px solid white;
                    width: 42px;
                    height: 30px;
                    margin-right: 20px;
                    text-align: center;
                    font-size: 16px;
                    font-family: Quicksand;
                    color: #FFCC00;
                    background: none;
                  
                }
                
                .timeRemainingText {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    text-align: center;
                    margin-top: 5px;
                    color: #FFCC00;
                }
                
                .errorText {
                    font-family: Quicksand;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0em;
                    color: #FD180C;
                    margin-left: 5%;
                    /*margin-top: -20px;*/
                }
                
                .otpInputSection {
                    font-family: Quicksand;
                    color: #FFB800;
                }
                
                .OTPEnterText {
                    font-size: 18px;
                    font-weight: 600;
                    line-height: 26px;
                    letter-spacing: 0em;
                   
                }
                
                .OTPSentText {
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0.1em;
                    text-align:start;
                    
                }

                .otpInputError {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0em;
                    text-align: center;
                    color: #FD180C;
                    margin-top: -10px;
                }
                
                .didntGetOTP {
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 26px;
                    letter-spacing: 0.1em;
                    text-align: center;
                    color: #5E5E5E
                }
                
                .resendOTP {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 26px;
                    letter-spacing: 0.1em;
                    color: #0D6EFD;
                    cursor: pointer;
                }
                }


                @media (min-width: 2260px) {
                    .verifyTickContainer {
                        width: 100px;
                        margin: auto;
                        height: 100px;
                        padding: 20px;
                        border-radius: 50%;
                        /* background-color: #0D6EFD;  */
                        background: linear-gradient(to right, #D3A72F, #994145);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    .verifyTickIcon {
                        width: 80px;
                        height: 80px;
                    }
                    
                    .verifiedText {
                        font-family: Quicksand, sans-serif;
                        font-size: 20px;
                        font-weight: 600;
                        line-height: 26px;
                        letter-spacing: 0em;
                        text-align: center;
                        margin-top: 20px;
                        color: #FFFFFF;
                        margin-bottom: 8px;
                    
                    }
                    
                    .getStartedBtn {
                        width: 160px;
                        margin: auto !important;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 36px;
                        background-color: #FFCC00 !important;
                        color: #5A0223 !important;
                        border-radius: 25px !important;
                        
                    }
                    
                    .getStartedBtn:hover{
                        /* background-color: red !important; */
                    }
    
                    .otpInput {
                        border-bottom: 2px solid white;
                        width: 42px;
                        height: 30px;
                        margin-right: 20px;
                        text-align: center;
                        font-size: 16px;
                        font-family: Quicksand;
                        color: #FFCC00;
                        background: none;
                      
                    }
                    
                    .timeRemainingText {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                        text-align: center;
                        margin-top: 5px;
                        color: #FFCC00;
                    }
                    
                    .errorText {
                        font-family: Quicksand;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0em;
                        color: #FD180C;
                        margin-left: 5%;
                        /*margin-top: -20px;*/
                    }
                    
                    .otpInputSection {
                        font-family: Quicksand;
                        color: #FFB800;
                    }
                    
                    .OTPEnterText {
                        font-size: 18px;
                        font-weight: 600;
                        line-height: 26px;
                        letter-spacing: 0em;
                       
                    }
                    
                    .OTPSentText {
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0.1em;
                        text-align:start;
                        
                    }
    
                    .otpInputError {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0em;
                        text-align: center;
                        color: #FD180C;
                        margin-top: -10px;
                    }
                    
                    .didntGetOTP {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: 0.1em;
                        text-align: center;
                        color: #5E5E5E
                    }
                    
                    .resendOTP {
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 26px;
                        letter-spacing: 0.1em;
                        color: #0D6EFD;
                        cursor: pointer;
                    }
                    }