/* final this code */
.nav-menu {
  flex-direction: column;
  --bg-opacity: 1;
  z-index: 9999;
  top: 0;
  transition: right 0.3s ease-in-out;
  background-color: #fff !important;
  height: 100vh;
  width: 256px;
  position: fixed;
  right: -350px;
  padding: 0px;
}
.nav-menu.active {
  --bg-opacity: 1;
  background-color: #22282d;
  background-color: #fff !important;
  z-index: 9999;
  top: 0;
  transition: right 0.3s ease-in-out;
  position: fixed;
  height: 100vh;
  padding: 0px;
  right: 0;
  width: 20%;
}

.custom-profile {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 54px;
  padding: 0px 15px;
  background: linear-gradient(to right, #5A0223, #1E2744);
  color: #ffff;
}
.custom-profile h1 {
  font-size: 22px;
  font-weight: 600;
  display: flex;
  align-items: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}
.custom-profile svg.person-outline {
  width: 42px;
  height: 42px;
  margin: 11px 10px;
}
.profile-list svg.profile-icons {
  margin-right: 20px;
  font-size: 22px;
}

.image-icons{
  margin-right: 20px;
  width: 23px;
  height: 24px;
}

.movies-sidebar-wrapper .profile-list li.nav-item {
  line-height: 60px;
  color: rgb(102, 102, 102);
  border-bottom: 1px solid #FFCC00 !important;
  padding: 0;
  list-style: none;
}
.movies-sidebar-wrapper .profile-list a.nav-link {
  position: relative;
  font-weight: 500;
  color: #363636;
  font-size: 14px;
  line-height: 25px;
  padding: 0px 15px;
}

.aboutclass {
  position: relative;
  font-weight: 500;
  color: #363636;
  font-size: 14px;
  padding: 0px 15px;
  line-height: 30px;
}

.movies-sidebar-wrapper .offcanvas-header {
  display: flex;
  align-items: normal !important;
  justify-content: space-between;
  padding: 0px !important;
}

.custom-profile svg.person-outline {
  color: #ffff;
}

svg.MuiSvgIcon-root.sideicons:hover {
  color: #ffcc00 !important;
}
a.nav-link:hover {
  color: #ffcc00 !important;
}
/* OTP login css */

.OTPModalStyl {
  margin-top: 360px !important;
  /* margin-left: 320px; */
  /* padding: 10px 30px 20px 80px !important; */
  /* position: sticky; */
  background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
  width: 924px;
  margin: auto !important;
  height: 224px;
  border-radius: 30px;
  display: flex !important;
  align-items: center;
  justify-content: center;}

  @media (min-width: 1025px) and (max-width: 1460px) {
    .OTPModalStyl {
    margin-top: 250px !important;
    height: 320px;
    }
    .aboutclass {
      line-height: 45px;
    }
  }

  @media (min-width: 1460px) and (max-width: 1960px) {
    .OTPModalStyl {
    margin-top: 290px !important;
    height: 340px;
    }
  }

  @media (min-width: 1961px) and (max-width: 2360px) {
    .OTPModalStyl {
    margin-top: 350px !important;
    height: 340px;
    }
  }

  @media (min-width: 2360px)  {
    .OTPModalStyl {
    margin-top: 350px !important;
    height: 340px;
    }
  }


@media (min-width: 320px) and (max-width: 350px) {
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 10px;
    /* line-height: 38px !important; */
    padding: 4px 15px;
  }

  .aboutclass {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 10px;
    line-height: 30px !important;
    padding: 0px 15px;
  }

}

@media (min-width: 350px) and (max-width: 380px) {
.movies-sidebar-wrapper .profile-list a.nav-link {
  position: relative;
  font-weight: 500;
  color: #363636;
  font-size: 11px;
  line-height: 30px !important;
  padding: 4px 15px;
}

.aboutclass {
  position: relative;
  font-weight: 500;
  color: #363636;
  font-size: 11px;
  line-height: 30px !important;
  padding: 0px 15px;
}

}

@media (min-width: 320px) and (max-width: 380px) {
  .OTPModalStyl{
    margin-top: 100px;
    margin-left: 5%;
    margin-right: 5%;
    /* padding: 10px 30px 20px 10px; */
    position: relative;
    top: 60px;
    background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
    border-radius: 18px;
    display:'flex';
    align-items: center;
    width: 328px;
    height: 360px;
}
  
 
  .custom-profile h1 {
    font-size: 16px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .nav-menu.active {
    right: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    width: 50%;
    height: 650px;
    border-radius: 2px;
    background-color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

@media (min-width: 381px) and (max-width: 480px) {
 

.OTPModalStyl{
  margin-top: 20% !important;
  /* padding: 10px 30px 20px 10px; */
  background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
  border-radius: 18px;
  display:'flex';
  align-items: center;
  width: 328px;
  margin: auto;
  height: 360px;
}
  
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 12px;
    line-height: 30px !important;
    padding: 4px 15px;
  }

  .aboutclass {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 13px;
    line-height: 40px !important;
    padding: 0px 15px;
  }

  .custom-profile h1 {
    font-size: 16px !important;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  .nav-menu.active {
    right: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    width: 50%;
    height: 650px;
    border-radius: 2px;
    background-color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

@media (min-width: 481px) and (max-width: 580px) {
 

.OTPModalStyl{
  margin-top: 20% !important;
  padding: 20px 40px 30px 20px;
  background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
  border-radius: 18px;
  display:'flex';
  align-items: center;
  width: 400px;
    height: 360px;
  margin: auto;
 
}


  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 14px;
    padding: 4px 16px;
    line-height: 40px !important;
  }

  .aboutclass {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 14px;
    padding: 0px 16px;
    line-height: 40px !important;
  }

  .movies-sidebar-wrapper .profile-list li.nav-item {
    line-height: 44px !important;
  }
  .custom-profile h1 {
    font-size: 18px !important;
  }
  .custom-profile svg.person-outline {
    width: 30px;
    height: 30px;
    margin: 13px 14px;
  }
  .nav-menu.active {
    right: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    width: 35%;
    height: 650px;
    border-radius: 2px;
    background-color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

@media (min-width: 581px) and (max-width: 780px) {
  .OTPModalStyl{
    margin-top: 15%;
    margin-left: 15%;
    margin-right: 15%;
    padding: 20px 40px 30px 20px;
    width: 90%;
    height: 280px;
    position: relative;
    top: 180px;
}
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 14px;
    padding: 4px 15px;
    line-height: 40px !important;
  }

  .aboutclass {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 14px;
    line-height: 45px !important;
    padding: 0px 16px;
  }

  .movies-sidebar-wrapper .profile-list li.nav-item {
    line-height: 44px !important;
  }
  .custom-profile h1 {
    font-size: 18px !important;
  }
  .custom-profile svg.person-outline {
    width: 30px;
    height: 30px;
    margin: 13px 14px;
  }
  .nav-menu.active {
    right: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    width: 35%;
    height: 650px;
    border-radius: 2px;
    background-color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
}

@media (min-width: 768px) and (max-width: 1025px) {

    .OTPModalStyl{
      margin-top: 152px;
      margin-left: 130px;
      padding: 10px 30px 20px 80px;
      width: 90%;
      height: 300px;
      position: relative;
      top: 180px;
  }
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 13px;
    padding: 4px 15px;
    line-height: 40px !important;
  }

  .aboutclass {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 13px;
    line-height: 45px !important;
    padding: 0px 20px;
  }

  .nav-menu.active {
    right: 0%;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    margin: 0px;
    padding: 0px;
    border-radius: 2px;
    width: 30%;
    height: 650px;
    border-radius: 2px;
    background-color: #fff !important;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    -ms-border-radius: 2px;
    -o-border-radius: 2px;
  }
  .custom-profile h1 {
    font-size: 20px;
  }
  .custom-profile svg.person-outline {
    width: 35px;
    height: 35px;
    margin: 14px 10px;
  }
  .movies-sidebar-wrapper .profile-list li.nav-item {
    line-height: 42px;
  }
  
}

.side-nav-user-photo{
  width: 45px;
  height: 45px;
  margin-top: 5px;
  border-radius: 50%;
}

@media (min-width: 320px) and (max-width: 480px) {
  .paddingAbout{
  padding-left: 56px !important;
  }
}


@media (min-width: 481px) and (max-width: 540px) {
  .paddingAbout{
  padding-left: 54px !important;
  }
}

@media (min-width: 541px) and (max-width: 580px) {
  .paddingAbout{
  padding-left: 50px !important;
  }
}

@media (min-width: 581px) and (max-width: 768px) {
  .paddingAbout{
  padding-left: 50px !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .paddingAbout{
  padding-left: 50px !important;
  }
}


@media (min-width: 1024px) and (max-width: 1200px) {
  .paddingAbout{
  padding-left: 54px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
  }
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 13px;
    padding: 4px 15px;
    line-height: 40px !important;
  }
}



@media screen and (min-width: 1200px) {
  .three-titles {
    display: none;
  }
  .movies-sidebar-wrapper .profile-list a.nav-link {
    position: relative;
    font-weight: 500;
    color: #363636;
    font-size: 13px;
    padding: 4px 15px;
    line-height: 40px !important;
  }
  .aboutclass {
    line-height: 40px !important;
  }
}


@media (min-width: 320px) and (max-width: 560px)  {
  .otpsucess {
    
      /* margin-left: 320px; *
      /* position: sticky; */
      background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
      width: 90%;
      margin: auto !important;
      height: 340px;
      border-radius: 30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 134px !important;
  }
  }

  @media (min-width: 561px) and (max-width: 860px)  {
    .otpsucess {
      
        /* margin-left: 320px; *
        /* position: sticky; */
        background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
        width: 90%;
        margin: auto !important;
        height: 340px;
        border-radius: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 134px !important;
    }
    }

    @media (min-width: 861px) and (max-width: 1025px)  {
      .otpsucess {
        
          /* margin-left: 320px; *
          /* position: sticky; */
          background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
          width: 70%;
          margin: auto !important;
          height: 340px;
          border-radius: 30px;
          display: flex !important;
          align-items: center;
          justify-content: center;
          margin-top: 200px !important;
      }
      }

      @media (min-width: 1025px) and (max-width: 1300px)  {
        .otpsucess {
          
            /* margin-left: 320px; *
            /* position: sticky; */
            background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
            width: 40%;
            margin: auto !important;
            height: 340px;
            border-radius: 30px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-top: 200px !important;
        }
        }

      @media (min-width: 1300px) and (max-width: 1500px)  {
        .otpsucess {
          
            /* margin-left: 320px; *
            /* position: sticky; */
            background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
            width: 30%;
            margin: auto !important;
            height: 360px;
            border-radius: 30px;
            display: flex !important;
            align-items: center;
            justify-content: center;
            margin-top: 240px !important;
        }
        }

@media (min-width: 1500px) and (max-width: 2260px)  {
  .otpsucess {
    
      /* margin-left: 320px; */
     
      /* position: sticky; */
      background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
      width: 30%;
      margin: auto !important;
      height: 360px;
      border-radius: 30px;
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin-top: 240px !important;
  }
  }


  @media (min-width: 2260px) {
    .otpsucess {
      
        /* margin-left: 320px; */
       
        /* position: sticky; */
        background: linear-gradient(95.14deg, #5A0223 11.03%, #1E2744 110.34%);
        width: 30%;
        margin: auto !important;
        height: 360px;
        border-radius: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        margin-top: 240px !important;
    }
    }


