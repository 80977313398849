/* modal css */
.custom-modal.modal {
  z-index: 9999999;
}
.custom-modal .modal-content .modal-header {
  border-bottom: none;
  background-color: #ffcc00;
  color: #0d6efd;
}
.custom-modal button.btn-close {
  margin: 0px;
  position: absolute;
  right: 7px;
  top: 7px;
  font-size: 14px;
}
.modal-backdrop {
  z-index: unset !important;
}
.modal-body.modal-body-outer-sec {
  padding: 2rem;
}
.modal-body.modal-body-outer-sec .form-control:focus {
  box-shadow: none;
  border-color: #ffcc00;
}
.modal-links {
  color: #AE2D0E;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 5px;
  text-decoration: none;
}
/* modal btn css*/
.modal-submit-btn button.btn {
  background: linear-gradient(90deg, #FFCC00 0%, #FFB800 100%);
  border-radius: 10px;
  height: 50px;
  color: #5A0223 !important;

  font-weight: 600;
  width: 451px;
  padding: 10px, 60px, 10px, 60px;
}
/* modal close btn css*/
.btn-close:focus {
  box-shadow: none !important;
}
@media screen and (min-device-width: 251px) and (max-device-width: 480px) {
  .modal-submit-btn button.btn {
    width: 100%
  }
}
