.search-container {
  position: relative;
  display: inline-block;
  margin: 0px 15px;
}

.search-container .searchIcon {
  position: absolute;
  top: 4px;
  right: 6px;
  height: 20px;
  width: 20px;
  margin: 0 !important;
  pointer-events: none !important;
  
}

.search-container input.input-open {
  /* width: 240px; */
  font-size: 16px;
  padding: 3px 12px;
  border-radius: 15px;
  background: none;
  border: 1px solid #fff;
  color: #fff;
  font-size: 14px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}
.search-container input.form-control.input-open::placeholder {
  color: #fff;
}
.material-icons svg.MuiSvgIcon-root:hover {
  color: #ffcb05;
}
.material-icons :focus {
  box-shadow: unset !important;
}
.search-container .result-holder {
  width: 240px;
  position: absolute;
  background: #ffff;
  border-radius: 3px;
  cursor: pointer;
  padding: 0px;
  max-height: 15rem;
  overflow: auto;
}

.search-container .searchIcon:hover {
  color: #ffff !important;
}
.result-show .title a.nav-link {
  color: #000;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
}
.result-show .title a.nav-link:hover {
  background-color: #ffcb05;
  color: #fff !important;
}
.title span.content-title {
  font-size: 14px;
  font-weight: 400;
}
span.subtitle {
  font-size: 12px;
  font-weight: 400;
}

@media screen and (min-device-width: 321px) and (max-device-width: 420px) {
  .search-container .searchIcon {
    display: flex;
    position: absolute;
    font-size: 2px;
    pointer-events: none !important;
   } 
   .search-container {
    position: relative;
    display: inline-block;
    margin: 0px 0px;
  }
  .search-container input.input-open {
    display: none;
    font-size: 16px;
    padding-left: 2px;
    border-radius: 15px;
  }
  .search-container input.input-open {
    /* width: 240px; */
    font-size: 16px;
    padding: 3px 3px;
    border-radius: 15px;
    background: none;
}
}

@media screen and (min-device-width: 321px) and (max-device-width: 359px) {
  .search-container input.input-open {
    display: none !important;
  }
  .searchIcon {
    display: none !important;
  }
}
