.uaTitle{
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    width: 50%;
    margin: auto;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
}

.uaText{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 4%;
}

span.highlightColor{
    color: #0D6EFD;
}

.uaQuestionTitle{
    font-size: 20px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 3%;
}

.uaAnswerChar{
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

.uaAnswer{
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0em;
}

.uaDefinitionTitle{
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    margin-top: 3%;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;

}

.uaDefinitionText{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    margin-top: 3%;
    text-align: justify;
}

.uaDefinitionPointChar{
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0em;
}

.uaDefinitionSubPoint{
    list-style-type: upper-roman;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    margin-left: 1%;
}

.uaDefinitionSubPoint > li::marker {
    font-weight: 500;
}

@media screen and (min-device-width: 300px) and (max-device-width: 480px){
    .uaTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 50%;
        margin: auto;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .uaText{
        font-size: 10px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
    }

    
    .uaQuestionTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaAnswerChar{
        font-size: 12px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaAnswer{
        font-size: 10px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionTitle{
        font-size: 14px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    
    }
    
    .uaDefinitionText{
        font-size: 10px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaDefinitionPointChar{
        font-size: 10px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionSubPoint{
        list-style-type: upper-roman;
        font-size: 10px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-left: 1%;
    }

}
@media screen and (min-device-width: 481px) and (max-device-width: 680px){
    .uaTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 50%;
        margin: auto;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .uaText{
        font-size: 12px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
    }
    
    .uaQuestionTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaAnswerChar{
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaAnswer{
        font-size: 12px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionTitle{
        font-size: 18px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    
    }
    
    .uaDefinitionText{
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaDefinitionPointChar{
        font-size: 12px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionSubPoint{
        list-style-type: upper-roman;
        font-size: 12px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-left: 1%;
    }

}

@media screen and (min-device-width: 681px) and (max-device-width: 980px){
    .uaTitle{
        font-size: 22px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: center;
        width: 50%;
        margin: auto;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .uaText{
        font-size: 14px;
        font-weight: 400;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
    }
    
    .uaQuestionTitle{
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaAnswerChar{
        font-size: 16px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaAnswer{
        font-size: 14px;
        font-weight: 400;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionTitle{
        font-size: 22px;
        font-weight: 600;
        line-height: 32px;
        letter-spacing: 0em;
        margin-top: 3%;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    
    }
    
    .uaDefinitionText{
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-top: 3%;
    }
    
    .uaDefinitionPointChar{
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
    }
    
    .uaDefinitionSubPoint{
        list-style-type: upper-roman;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0em;
        margin-left: 1%;
    }

}