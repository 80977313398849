/* div{
    --level1marginLeft:7%;

} */


.privacyPolicyTitle{
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5A0223;
    text-align: center;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    width: 40%;
    margin: auto;
}

.privacyPolicySubText{
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 4%;
    width: 75%;
}

span.highlightColor{
    color: #0D6EFD;
}


.level1Title{
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: #5A0223;
    margin-top: 4%;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    margin-left: var(--level1marginLeft);
}

.level1Text{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 3%;
    margin-left: var(--level1marginLeft);
}

.level2Title{
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    margin-top: 3%;
    margin-left: var(--level1marginLeft);
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    margin-left: 2%;

}

.level2Text{
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: justify;
    margin-left: 4%;
    margin-top:2%
}



@media screen and (min-device-width: 300px) and (max-device-width: 480px){
    .privacyPolicyTitle{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        text-align: center;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;
        margin: auto;
    }
    
    .privacyPolicySubText{
        font-size: 10px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
        width: 75%;
    }

    .level1Title{
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        margin-top: 4%;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .level1Text{
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 3%;
        margin-left: 0px;
    }
    
    .level2Title{
        font-size: 12px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        margin-top: 3%;
        margin-left:0px;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        
    
    }
    
    .level2Text{
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: 0px;
    }
}


@media screen and (min-device-width: 481px) and (max-device-width: 680px){
    .privacyPolicyTitle{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        text-align: center;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;
        margin: auto;
    }
    
    .privacyPolicySubText{
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
        width: 75%;
    }

    .level1Title{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        margin-top: 4%;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .level1Text{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 3%;
        margin-left: 0px;
    }
    
    .level2Title{
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        margin-top: 3%;
        margin-left:0px;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        
    
    }
    
    .level2Text{
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: 0px;
    }
}


@media screen and (min-device-width: 681px) and (max-device-width: 980px){
    .privacyPolicyTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        text-align: center;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        width: 40%;
        margin: auto;
    }
    
    .privacyPolicySubText{
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 4%;
        width: 75%;
    }

    .level1Title{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        color: #5A0223;
        margin-top: 4%;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
    }
    
    .level1Text{
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: justify;
        margin-top: 3%;
        margin-left: 0px;
    }
    
    .level2Title{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0em;
        margin-top: 3%;
        margin-left:0px;
        color: #5A0223;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 12px;
        
    
    }
    
    .level2Text{
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: justify;
        margin-left: 0px;
    }
}