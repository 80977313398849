span.highlightColor{
    color: #0D6EFD;
}

.faqTitle{
    font-size: 26px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    width: 100%;
    /* margin: auto; */
}
.faqSubText{
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: center;
    width: 75%;
}

.faqMainText{
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 2%;
}

.faqMainTextBold{
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
}

.faqLevel1Title{
    font-size: 22px;
    font-weight: 500;
    /* line-height: 40px; */
    letter-spacing: 0em;
    text-align: left;
    margin-top: 4%;
    color: #5A0223;
    background: #FFCB05;
    border-radius: 42px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 22px;
}

.faqPoints{
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    text-align:justify;
    margin-top: 4%;
    margin-bottom: 1%;
}

.faqQuestion{
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 2%;
}
.faqAnswer{
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 1%;
    margin-left: 2%;
}

.faqAnswerpoints{
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: justify;
    margin-top: 1%;
    margin-left: 2%;
    display: flex;   
}

.faqAnswerList{
    margin-top: 0.4%;  
}




.faqAnswerChar{
    font-size: 16px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0em;
}

.faqbold{
    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    letter-spacing: 0em;
}
@media screen and (min-device-width: 300px) and (max-device-width: 480px) {    
    .faqTitle{
        font-size: 14px;
        font-weight: 500;
        border-radius: 42px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        width: 100%;
        margin: auto;
    }
    .faqSubText{
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        width: 75%;
    }
    
    .faqMainText{
        font-size: 10px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    
    .faqMainTextBold{
        font-size: 10px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
    .faqLevel1Title{
        font-size: 12px;
        font-weight: 500;
    }
    .faqPoints{
        font-size: 10px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 4%;
        margin-bottom: 1%;
    }
    
    .faqQuestion{
        font-size: 10px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    .faqAnswer{
        font-size: 10px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
    }
    
    .faqAnswerpoints{
        font-size: 10px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
        display: flex;   
    }
    
    .faqAnswerList{
        margin-top: 0.4%;  
    }
    
    
    
    
    .faqAnswerChar{
        font-size: 10px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .faqbold{
        font-size: 10px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
}
@media screen and (min-device-width: 481px) and (max-device-width: 680px) {    
    .faqTitle{
        font-size: 16px;
        font-weight: 500;
        border-radius: 42px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        width: 60%;
        margin: auto;
    }
    .faqSubText{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        width: 75%;
    }
    
    .faqMainText{
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    
    .faqMainTextBold{
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
    .faqLevel1Title{
        font-size: 14px;
        font-weight: 500;
        /* line-height: 40px; */
        letter-spacing: 0em;
        text-align: left;
        margin-top: 4%;
        color: white;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 22px;
    }
    
    .faqPoints{
        font-size: 12px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 4%;
        margin-bottom: 1%;
    }
    
    .faqQuestion{
        font-size: 12px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    .faqAnswer{
        font-size: 12px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
    }
    
    .faqAnswerpoints{
        font-size: 12px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
        display: flex;   
    }
    
    .faqAnswerList{
        margin-top: 0.4%;  
    }
    
    
    
    
    .faqAnswerChar{
        font-size: 12px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    
    .faqbold{
        font-size: 12px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
}
@media screen and (min-device-width: 681px) and (max-device-width: 980px) {    
    .faqTitle{
        font-size: 18px;
        font-weight: 500;
        border-radius: 42px;
        padding-top: 12px;
        padding-bottom: 12px;
        padding-left: 12px;
        width: 70%;
        margin: auto;
    }
    .faqSubText{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: center;
        width: 75%;
    }
    
    .faqMainText{
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    
    .faqMainTextBold{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
    .faqLevel1Title{
        font-size: 16px;
        font-weight: 500;
        /* line-height: 40px; */
        letter-spacing: 0em;
        text-align: left;
        margin-top: 4%;
        color: white;
        background: #FFCB05;
        border-radius: 42px;
        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 22px;
    }
    
    .faqPoints{
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 4%;
        margin-bottom: 1%;
    }
    
    .faqQuestion{
        font-size: 14px;
        font-weight: 600;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 2%;
    }
    .faqAnswer{
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
    }
    
    .faqAnswerpoints{
        font-size: 14px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0em;
        text-align: left;
        margin-top: 1%;
        margin-left: 2%;
        display: flex;   
    }
    
    .faqAnswerList{
        margin-top: 0.4%;  
    }
    .faqAnswerChar{
        font-size: 14px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0em;
    }
    .faqbold{
        font-size: 14px;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 0em;
    }
    
}